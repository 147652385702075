import { Module } from "vuex";

import { RootState } from "../root";
import { Group } from "@/core/models";

// getters
import getters from "./getters";
import postGetters from "./posts/getters";
import videoGetters from "./videos/getters";
import userGetters from "./users/getters";

// actions
import actions from "./actions";
import postActions from "./posts/actions";
import videoActions from "./videos/actions";
import userActions from "./users/actions";

// mutations
import mutations from "./mutations";

export interface GroupsState {
  groups: Group[];
  publicGroups: Group[];
  loadingGroups: boolean;
  loadingPublicGroups: boolean;
  retrievedGroups: boolean;
  retrievedPublicGroups: boolean;

  // Helper loadings
  addUsersLoading: boolean;
  changeUserRoleLoading: boolean;
  removeUserLoading: boolean;
  addGroupLoading: boolean;
  removeGroupLoading: boolean;
  renameGroupLoading: boolean;
  addVideoLoading: boolean;
  editVideoLoading: boolean;
  deleteVideoLoading: boolean;
  addPostLoading: boolean;
  editPostLoading: boolean;
  deletePostLoading: boolean;

  // Loading Helpers
  editedUserEmail: string;
  removedUserEmail: string;
  removedGroupID: number;
  editedVideoID: number;
  deletedVideoID: number;
  editedPostID: number;
  deletedPostID: number;
}

export const state: GroupsState = {
  publicGroups: [],
  groups: [],
  loadingGroups: false,
  loadingPublicGroups: false,
  retrievedGroups: false,
  retrievedPublicGroups: false,

  // Loadings
  addUsersLoading: false,
  changeUserRoleLoading: false,
  removeUserLoading: false,
  addGroupLoading: false,
  removeGroupLoading: false,
  renameGroupLoading: false,
  addVideoLoading: false,
  editVideoLoading: false,
  deleteVideoLoading: false,
  addPostLoading: false,
  editPostLoading: false,
  deletePostLoading: false,

  // Loading Helpers
  editedUserEmail: "",
  removedUserEmail: "",
  removedGroupID: 0,
  editedVideoID: 0,
  deletedVideoID: 0,
  editedPostID: 0,
  deletedPostID: 0,
};

export const groups: Module<GroupsState, RootState> = {
  state,
  namespaced: true,
  mutations: { ...mutations },
  getters: { ...getters, ...postGetters, ...videoGetters, ...userGetters },
  actions: { ...actions, ...postActions, ...videoActions, ...userActions },
};
