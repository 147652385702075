import { ActionTree } from "vuex";

import { GroupsState } from ".";
import api from "@/core/utils/api";
import { RootState } from "../root";
import { Group } from "@/core/models";
import i18n from "@/core/plugins/i18n";

const actions: ActionTree<GroupsState, RootState> = {
  clearGroups({ state }) {
    state.groups = [];
    state.retrievedGroups = false;
  },

  addGroupToList: ({ state }, group) => state.groups.push(group),

  async getGroups({ state }) {
    state.loadingGroups = true;
    state.retrievedGroups = false;
    try {
      const res = (await api.get("/api/Groups/")) as any[];
      console.log("API response:", res);
      state.groups = res.map(g => Group.parseGroup(g));
      state.retrievedGroups = true;
    } catch (error) {
      console.log(error);
    }
    state.loadingGroups = false;
  },

  async getPublicGroups({ state }) {
    state.loadingPublicGroups = true;
    state.retrievedPublicGroups = false;
    try {
      const res = (await api.get("/api/Groups/Public")) as any[];
      state.publicGroups = res.map(g => Group.parseGroup(g));
      state.retrievedPublicGroups = true;
    } catch (error) {
      console.log(error);
    }
    state.loadingPublicGroups = false;
  },

  async addGroup({ commit, state }, data: object) {
    state.addGroupLoading = true;
    try {
      const res = await api.post("/api/Groups/AddGroup", data);
      state.groups.push(Group.parseGroup(res));
      const msg = i18n.t("snack.groups.added").toString();
      commit("displaySnackbar", msg, { root: true });
    } catch (error) {
      console.log(error);
    }
    state.addGroupLoading = false;
  },

  async renameGroup({ state }, data: any) {
    state.renameGroupLoading = true;
    try {
      await api.put("/api/Groups/RenameGroup", data);
      const { groupId, newName } = data;
      const idx = state.groups.findIndex(x => x.ID === groupId);
      if (idx !== -1) state.groups[idx].Name = newName;
    } catch (error) {
      console.log(error);
    }
    state.renameGroupLoading = false;
  },

  async removeGroup({ commit, state }, groupId: number) {
    state.removeGroupLoading = true;
    state.removedGroupID = groupId;
    try {
      const endpoint = `/api/Groups/RemoveGroup?groupId=${groupId}`;
      await api.delete(endpoint);
      const idx = state.groups.findIndex(g => g.ID == groupId);
      if (idx !== -1) state.groups.splice(idx, 1);
      const msg = i18n.t("snack.groups.del").toString();
      commit("displaySnackbar", msg, { root: true });
    } catch (error) {
      console.log(error);
    }
    state.removeGroupLoading = false;
    state.removedGroupID = 0;
  },
};

export default actions;
