const en = {
  dashboardPage: {
    yourGoalsCard: {
      yourGoals: "Your Goals",
      haveCompleted: "You have completed {number} session{plural}",
      error: "An error occurred.",
      loadingGoals: "Loading Goals...",
    },
    groupsCard: {
      groups: "Groups",
      viewAll: "View All",
      information: "Information",
      created: "Created:",
      users: "Users",
      videos: "Videos",
      posts: "Posts",
      notGroupMember: "You are not part of any groups yet.",
      groupsLoading: "Groups are loading...",
    },
    videosCard: {
      all: "All",
      videos: "Videos",
      group: "Group",
      loadingVids: "Loading videos...",
      noGroups: "You are not a part of any groups",
    },
    newsCard: {
      news: "News",
      readAll: "Read All",
      posted: "Posted",
      readMore: "Read Article",
      noNews: "No news has been posted yet.",
      newsLoading: "News is loading.",
    },
    sessionsCard: {
      sessions: "Progress Breakdown",
      pitchHdg: "Pitch",
      pitchSubHdgDeeper:
        "You spoke in a deeper voice this week when compared to last week.",
      ptichSubHdgHigher:
        "You spoke in a higher voice this week when compared to last week.",
      speechSpdHdg: "Speech Speed",
      speechSpdSubHdgFaster:
        "You spoke faster today in comparison to yesterday.",
      speechSpdSubHdgSlower:
        "You spoke slower today in comparison to yesterday.",
      hertz400: "400Hz",
      hertz600: "600Hz",
      thisWeek: "This Week",
      wpm: "words/minute",
      today: "Today",
      yesterday: "Yesterday",
    },
    presentationsCard: {
      presentations: "Presentations",
      mb: "MB",
      empty: "Empty",
    },
  },
  //isnt used
  topPanel: {
    settings: "Settings",
    mail: "Mail",
    refresh: "Refresh",
    logout: "Logout",
  },
  //isnt used
  leftPanel: {
    dashboard: "Dashboard",
    sessions: "Sessions",
    groups: "Groups",
    news: "News",
    goals: "Goals",
    meetings: "Meetings",
    adminPanel: "Admin Panel",
  },
  sessionsPage: {
    noSessions: "No sessions could be found",
    sessionsFor: "Sessions for ",
    yourSessionsSection: {
      yourSessions: "Sessions",
      sessionFilters: "Session Filters",
      filterByDate: "Filter by date",
      beforeDate: "Before date",
      afterDate: "After date",
      betweenTwoDates: "Between two dates",
      filterByDuration: "Filter by duration",
      timeSpan: "Time span (minutes)",
      longerThan: "Longer than (minutes)",
      shorterThan: "Shorter than (minutes)",
      minTime: "Minimum time (minutes)",
      maxTime: "Maximum time (minutes)",
      filterByName: "Filter by name",
      startsWith: "Starts with",
      endsWith: "Ends with",
      includes: "Includes",
      exactMatch: "Exact match",
      searchTerm: "Search term",
      caseInsensitive: "Case insensitive",
      filterByScore: "Filter by score",
      greaterThanScore: "Greater than",
      lessThanScore: "Less than",
      scoreRange: "Score range",
      applyButton: "Apply",
      clearButton: "Clear",
      cancelButton: "Cancel",
      fetching: "Fetching session...",
    },
    sessionsListSection: {
      sessionsList: "Sessions List",
      sessions: "Sessions",
      checkSessions: "Refresh",
      audioAnalHdg: "Audio Analysis",
      aiBasedAnal: "AI-Based Analysis",
      audioAnalNotComplete:
        "Audio Analysis hasn't been completed yet. You can wait for it to load or check if it is done by refreshing the page.",
      textAnalHdg: "Text Analysis",
      textAnalNotComplete:
        "Text Analysis not completed. You can refresh the page to check if it is ready or wait for the session to complete.",
      movementAnalHdg: "Movement Analysis",
      movementAnalNoData: "There is no movement data available.",
      show: "Show",
      points: "points",
      volumeWarning:
        "A too quiet or loud recording may influence the quality of the Analysis Results. To get precise Analysis Results, aim for a Voice Volume between 20% and 80%.",
      movementAnalCard: {
        movementAnal: "Movement Analysis",
        headMovementOverTime: "Head Movement over Time",
        headMoTInfo:
          "Head Movement over Time shows how much your movement has changed over the last few sessions.",
        headMovement: "Head Movement",
        avgHeadMovement: "Average head movement",
        infoHeadM:
          "Head Movement shows how much you move with your Head / Body. Try to move from one space to another with reason and purpose.",
        handMovementOverTime: "Hand Movement over Time",
        handMoTInfo:
          "Hand Movement over Time shows how much your movement has changed over the last few sessions.",
        handMovement: "Hand Movement",
        avgHandMovement: "Average hand movement",
        infoHandM:
          "Hand Movement shows how much you move your Hands / Gesture. A lot of movement may cause you to appear nervous and less movement may portray you as bored. Try to use your Gestures as a tool to build tension.",
        distance: "Distance",
        changeOverTime: "Change over time",
        timePercent: "Time in %",
      },
      speechSpeedCard: {
        cardTitle: "Speech Speed",
        wpmInfo:
          "This shows how fast you speak. / How many words you use in one minute on average.",
        wpmTimeUnit: "Words/Minute",
        wpmNotice:
          "For most presentations a value between 90 and 150 words per minute is suitable",
        infoTitle: "Speech Speed and Listening Speed",
        infoText:
          "An average speech speed between 90 and 150 words per minute (WPM) is considered pleasant and understandable. Listening we can understand 150 WPM just fine but we can read about at about 260 WPM! That means that listeners will prefer to read the text that is on the slide, rather than wait for the speaker to say the it out loud. Bear this in mind when creating your presentation slides. Check out the 'How-To' section for more info on how to create the perfect presentation.",
      },
      scoresCard: {
        infoTitle: "Session Scores",
        infoText: "These are the scores for this session",
        cardTitle: "Session Scores",
        overall: "Overall",
        overallTip:
          "Overall score is the average of all your individual scores. If you scored less than 3 in one area, this score is weighted triple!",
        other: "Other scores",
        wpm: "Speech Speed",
        wpmTip:
          "For most presentations a value between 90 and 150 words per minute is the best. You have spoken {value} words per minute. ",
        wpmFasterTip: "Try to speak a bit faster next time.",
        wpmSlowerTip: "Try to speak a bit slower next time.",
        pauses: "Pauses",
        pausesTip:
          "A good presentation should contain enough pauses (Pauses longer than 2 seconds). We recommend between 2 to 3 pauses per minute. You did {value} pauses per minute. ",
        morePausesTip: "Try to use more pauses. ",
        lessPausesTip:
          "To have a  more fluent presentation, try to reduce the count of pauses per minute to 2 - 3. ",
        repetition: "Repetition",
        repetitionTip:
          "You shouldn't use the same word more than two times per minute in a good presentation. You have used following words very often: ",
        filler: "Filler words",
        fillerTip:
          "A good presentation should not contain more than two filler words per minute. For this session, you used {value} filler words per minute. ",
        fillerTipWordlist: "These were: ",
        fillerTipContinued:
          "Short filler words (e.g. em) are sometimes not recognized by the system or misunderstood as single letters (e. g. M). Please listen to your presentation to check your use of filler words.",
        speaking: "Speech Clarity",
        speakingTip:
          "This score says how well understandable your presentation is. Try to reach a maximum value of 100%. Your score was {value}%. ",
        none: "No scores for this session",
      },
      feedback: {
        recordButton: "Feedback",
        recordTooltip: "Record a video as feedback",
        playButton: "View",
        playTooltip: "View your feedback",
        playDialogTitle: "Your feedback",
        deleteButton: "Delete",
        deleteTooltip: "Delete your feedback",
        deleteConfirmationTitle: "Delete feedback",
        deletionInfo:
          "You can't undo deleting your feedback. Are you sure you want to delete your feedback?",
        confirmButton: "Confirm",
        cancelButton: "Cancel",
      },
    },
    uploadSessionSection: {
      uploadSession: "Upload Session",
      createSessionFromAudio: "Create session from audio file",
      sessionName: "Session Name *",
      audioFile: "Audio File *",
      videoFile: "Video File *",
      chooseLang: "Choose language",
    },
    deleteSession: {
      deleteSessionButton: "Delete this session",
      youSureDelete: "Are you sure you would like to delete this session?",
      deletionInfo:
        "Deleting a session is irreversible. Are you sure you would like to continue with deletion of this session?",
      confirmSessDel: "Confirm",
      cancelSessDel: "Cancel",
    },
    sessionsProgressSection: {
      analysisStatus: {
        AnalyzingAudio: "Analyzing audio...",
        AnalyzingText: "Analyzing text...",
        AudioDone: "Audio analysis complete",
        ExtractingAudio: "Extracting audio",
        Running: "Running...",
        NotStarted: "Not started",
        Succeeded: "Succeeded",
        null: "Running...",
        TextDone: "Text transcription completed",
      },
      sessionsProgress: "Sessions Progress",
      analyzingSession: "Analyzing...",
      gettingSession: "Getting session...",
      recentData: "Recent Data",
      display: "Display",
      sessions: "sessions",
      audioAnal: "Audio Analysis",
      textAnal: "Text Analysis",
      movementAnal: "Movement Analysis",
      pitchOverTimeCard: {
        pitchOverTime: "Pitch over Time",
        changeOverTime: "Change over time",
        voicePitch: "Voice Pitch",
        infoPoT:
          "With Pitch over Time you can see the progress of your voice. This is useful if you want to train to use a deeper or higher voice.",
      },
      volumeOverTimeCard: {
        volumeOverTime: "Volume over Time",
        voiceVolume: "Voice Volume",
        changeOverTime: "Change over Time",
        infoVoT:
          "Volume over Time shows the progress of the volume of your voice.",
      },
      pausesOverTimeCard: {
        pausesOverTime: "Pauses-per-minute over Time",
        changeOverTime: "Change over time",
        totalPauseTime: "Total Pause Time",
        infoPoT:
          "Pauses over Time shows how many pauses you made within your last few sessions.",
      },
      textAnalCard: {
        sentimentOverTime: "Sentiment over Time",
        changeOverTime: "Change over time",
        sentiment: "Sentiment",
        infoSoT:
          "Sentiment over Time shows your progress in terms of a positive choice of words.",
      },
      speechSpeedOverTimeCard: {
        speechSpeedOverTime: "Speech Speed over Time",
        changeOverTime: "Change over time",
        wordsPerMinute: "Words/Minute",
        infoSSoT:
          "This shows the speed of your latest sessions relative to previous ones.",
      },
      movementAnalCard: {
        movementAnal: "Movement Analysis",
        headMovementOverTime: "Head Movement over Time",
        headMoTInfo:
          "Head Movement over Time shows how much your movement has changed over the last few sessions.",
        headMovement: "Head Movement",
        infoHeadM:
          "Head Movement shows how much you move with your Head / Body. Try to move from one space to another with reason and purpose.",
        handMovementOverTime: "Hand Movement over Time",
        handMoTInfo:
          "Hand Movement over Time shows how much your movement has changed over the last few sessions.",
        handMovement: "Hand Movement",
        infoHandM:
          "Hand Movement shows how much you move your Hands / Gesture. A lot of movement may cause you to appear nervous and less movement may portray you as bored. Try to use your Gestures as a tool to build tension.",
        distance: "Distance",
        changeOverTime: "Change over time",
        timePercent: "Time in %",
      },
    },
    sessionFiltersPopUp: {
      sessionFilters: "Session filters",
      filterByDate: "Filter by date",
      filterByDuration: "Filter by duration",
      minTimeMin: "Minimum time (minutes)",
      maxTimeMin: "Maximum time (minutes)",
      filterByName: "Filter by name",
      searchTerm: "Search term",
      caseInsensitive: "Case insensitive",
      filterByScore: "Filter by score",
      apply: "Apply",
      clear: "Clear",
      cancel: "Cancel",
      ok: "OK",
      beforeDate: "Before date",
      after: "After date",
      between: "Between two dates",
      startDate: "Start date",
      endDate: "End date",
    },
    aiFeedback: "Get personal Feedback From Your AI Coach",
    aiSessionTitle: "AI Assisted Session",
    new: "New",
  },
  groupsSections: {
    yourGroupsPage: {
      yourGroups: "Your groups",
      noGroups: "You are not part of any groups.",
      addNewGroup: "Add A New Group",
      addGroupButton: "Add Group",
      groupName: "Group Name",
      groupUsers: "Group Users",
      emailRules:
        "Separate e-mails with comma(,) or by pressing space, tab or enter",
      submitButton: "Submit",
    },
    //isnt used
    groupPage: {
      latestVideosCard: {
        latestVideos: "Latest videos",
        editVideo: "Edit video",
        deleteVideo: "Delete video",
        addVidButton: "Add Video",
        addNewVideo: "Add new video",
        vidTitle: "Video Title (optional)",
        info: "If the title is empty, it will be set automatically",
        vidDescription: "Video Description (optional)",
        pinVideo: "Pin this video?",
        pinfo: "The pinned video will appear first",
        linkButton: "Link",
        vidLink: "Video Link",
        submit: "Submit",
        fileButton: "File",
      },
      latestPostsCard: {
        latestPosts: "Latest posts",
        editPost: "Edit post",
        deletePost: "Delete post",
        addPostButton: "Add Post",
        postTitle: "Post Title (required)",
        postBody: "Post Body (required)",
        pinPost: "Pin this post?",
        uploadBannerImage: "Upload banner image?",
        bannerImage: "Banner Image",
        otherFiles: "Other files",
        postButton: "Post",
      },
      usersCard: {
        users: "Users",
        remove: "Remove User",
        makeManager: "Make Manager",
        removeManager: "Remove Manager",
      },
      addUserCard: {
        addANewUser: "Add A New User",
        addUserButton: "Add User",
        userEmail: "User E-mail",
        makeManager: "Make Manager",
        submitButton: "Submit",
      },
    },
  },
  latestNewsSections: {
    latestNewsPage: {
      latestNews: "Latest News",
      noNews: "No news posted yet.",
      newsLoading: "News is loading",
      read: "Read",
      back: "Back",
      edit: "Edit",
      delete: "Delete",
      err404: "404",
      artWithId: "Article with ID",
      notFound: "could not be found.",
      goBack: "Go Back",
      addArticleButton: "Add Article",
      addArticlePopUp: {
        addNewArticle: "Adds a News Article",
        articleTitle: "Article Title",
        cannotBeEmpty: "Field cannot be empty",
        articleBody: "Article Body",
        addBannerImg: "Add Banner Image",
        bannerImg: "Banner Image",
        otherFiles: "Other Files",
        addArticle: "Add Article",
      },
    },
    articleActions: {
      edit: "Edit",
      editArticle: "Edit Article",
      newArticleTitle: "New Article Title",
      newArticleBody: "New Article Body",
      done: "Done",
      deleteArticle: "Delete article",
      areYouSure: "Are you sure you would like to delete the current article?",
      confirm: "Confirm",
      cancel: "Cancel",
    },
    addArticleButton: {
      addArticle: "Add Article",
      addNewsArticle: "Add a News Article",
      articleTitle: "Article Title",
      articleBody: "Article Body",
      addBannerImage: "Add Banner Image",
      bannerImage: "Banner Image",
      otherFiles: "Other files",
    },
  },
  goalsSections: {
    yourGoals: "Your Goals",
    assignedGoalsCard: {
      assignedGoals: "Assigned Goals",
      completeNumSessions: "Complete #### sessions",
      dueDate: "Due in #### years",
      completedSessions: "You have completed #### sessions",
      noGoalAssignments: "You have not been assigned any goals.",
    },
    createdGoalsCard: {
      createdGoals: "Created Goals",
      completeNumSessions: "Complete #### sessions",
      goalCreatedAtMonth: "a month ago",
      goalCreatedAtMinutes: "x minutes ago",
      sessionsThatCount: "Only sessions with a score greater than ## count",
      sentimentAim: "Aim for a sentiment score between xx% and yy%",
      pausesAim: "Aim to make anywhere between x and y pauses",
      pitchAim: "Aim for an average voice pitch between ##Hz and ##Hz",
      volumeAim: "Aim for an average volume between ##dB and ##dB",
      noCreatedGoals: "You have not created any goals.",
    },
    addGoalButton: "Add Goal",
    addGoalPopUp: {
      addNewGoal: "Add new goal",
      addCondition: "Add Condition",
      conditionNum: "Condition #x",
      conditionType: "Condition Type (required)",
      dueDate: "Due Date",
      sessionScore: "Session Score",
      targetScore: "Target Score",
      sentiment: "Sentiment",
      targetSentiment: "Target Sentiment",
      avgPitch: "Average Pitch",
      targetPitch: "Target Pitch",
      avgVolume: "Average Volume",
      targetVolume: "Target Volume",
      pausesPerMin: "Pauses per Minute",
      targetPauses: "Target Pauses",
      assignToUsers: "Assign to users",
      selectAll: "Select All",
      deselectAll: "Deselect All",
      assignSelf: "Assign yourself?",
      submit: "Submit Goal",
    },
  },
  meetingsSection: {
    yourMeetings: "Your Meetings",
    allMeetingsCard: {
      allMeetings: "All Meetings",
      subject: "Subject",
      noSubject: "No Subject Set",
      noMeetings: "You have not created any meetings.",
      joinMeeting: "Join Meeting",
      editMeeting: "Edit Meeting",
      cancelMeeting: "Cancel Meeting",
      dateFuture: "in xx days",
      datePast: "xx days ago",
      done: "Done",
    },
    addMeetingButton: "Add Meeting",
    addNewMeetingPopUp: {
      addNewMeeting: "Add a New Meeting",
      meetingSub: "Meeting Subject",
      chooseStart: "Choose start date/time",
      chooseEnd: "Choose end date/time",
      assignUsers: "Assign to users",
      selectAll: "Select All",
      deselectAll: "Deselect All",
      additionalUsers: "Additional Users",
      emailRule:
        "Separate e-mails with a comma(,) or by pressing space, tab or enter",
      setPass: "Set password?",
      pass: "Password",
      meetingTypes: "Meeting Types",
      blizzMeeting: "Blizz Meeting",
      customMeeting: "Custom Meeting",
      customMeetingLink: "Custom meeting link",
      addMeeting: "Add Meeting",
      dateError: "Meeting end date has to be after its start date",
    },
  },
  adminPanelSection: {
    allUsers: "All Users",
    changeRole: "Change Role",
    changeRolePopUp: {
      changeRoleUser: "Change role for user",
      submit: "Submit",
      cancel: "Cancel",
      admin: "Admin",
      user: "User",
      owner: "Owner",
    },
  },
  passwordResetSection: {
    resetPass: "Reset Password",
    error: "An error occurred",
    newPassRequired: "New Password (required)",
    confirmPass: "Confirm New Password (required)",
    doneRedirect: "Done! Redirecting you to Login in 5-seconds.",
    couldNotResetPass: "Could not reset your password",
    resetMyPass: "Reset my password",
    backToForgotPass: 'Back to "Forgot Password"',
    passRequired: "Password is required",
    passMustBe: "Password must be at least 8 characters long.",
    passMustContain:
      "Password must include uppercase and lowercase letters, a digit, and a special character",
    confirmPassRequired: "Password confirmation is required.",
    passNoMatch: "Passwords do not match.",
  },
  registerSection: {
    error: "An error occurred",
    fNameRequired: "First Name (required)",
    lNameRequired: "Last Name (required)",
    firstName: "First Name",
    lastName: "Last Name",
    companyName: "Company Name",
    emailRequired: "E-mail (required)",
    passwordRequired: "Password (required)",
    confirmPassRequired: "Confirm Password (required)",
    emailPlaceHolder: "demo@prezp.com",
    agree: "I agree with the",
    termsNCond: "Terms and Conditions",
    agreeEnd: "",
    next: "Next",
    back: "Back",
    register: "Register",
    fName: "First name is required",
    fNameMin: "First name has to be at least 2-letters long",
    lName: "Last name is required",
    lNameMin: "Last name has to be at least 2-letters long",
    flName: "First name and last name are required",
    email: "E-mail is required",
    invalidEmail: "Invalid e-mail",
    emailAlreadyTaken: "A user with this E-mail already exists",
    passRequired: "Password is required",
    passMin: "Password must be at least {min} characters long",
    passMax: "Password cannot be more than {max} characters long",
    passRules:
      "Passwords must include lowercase letters, uppercase letters, digits, and special characters.",
    passwordExplanation:
      "Your password must be at least 8 characters long. It must include lowercase letters, uppercase letters, digits, and special characters.",
    confirmPass: "Password confirmation is required",
    passMismatch: "Passwords do not match",
    tOS: "Please read and agree to the Terms of Service",
    registerFailed: "An error occurred",
    validationError: "The data you provided is incomplete",
    termsAndServices:
      "https://prezpstore.blob.core.windows.net/site-files/Nutzungsbedingungen_Prezp_Deutsch_03.03.2021.pdf?sp=rl&st=2021-03-15T08:01:58Z&se=2098-01-16T08:01:00Z&sv=2020-02-10&sr=b&sig=jlq6cAdFw%2FuPNsqdmHorPnwhcVd8qzso7yMHwhVo9gM%3D",
    yourInfo: "Your information",
    yourEmail: "Email",
    yourName: "Username",
    registerToCompany: "Register to become part of the company",
    andSeminar: "and the seminar",
  },
  onboardingSection: {
    titleFirstPart: "YOUR COMMUNICATION TRAINING",
    titleSecondPart: "IN COMMUNICATION",
    bannerContent:
      "Would you like to communicate better,talk more grippingly and sell more successfully? Start now!",
    learnMoreBtn: "Learn more",
    footerLinkPrivacy: "Privacy",
    footerLinkLegal: "Legal",
    footerLinkContact: "Contact",
  },
  loginSection: {
    dontHaveAccount: "Don't have an account yet?",
    register: "Register",
    signIn: "Sign In",
    title: "Sign in to PREZP",
    error: "An error occurred",
    unconfirmedEmail:
      "Please confirm your email by clicking on the link in your registration mail. Your account is not yet activated.",
    wrongPasswordError:
      "Your user name or your password was not entered correctly!",
    unauthorizedError:
      "Authorization error. Please make sure you are signed in.",
    email: "E-mail",
    password: "Password",
    forgotPass: "Forgot password",
    emailPlaceHolder: "demo@prezp.com",
    invalidEmail: "Invalid e-mail",
    emailRequired: "E-mail is required",
    passRequired: "Password is required",
    passMin: "Password must be at least 6-characters long",
    passMax: "Password cannot be more than 16-characters long",
  },
  newUser: {
    thanks:
      "Thank you for registering an account on PREZP. Plase confirm your email by clicking on the link in your registration mail to activate your account.",
    welcome: "Welcome to PREZP",
  },
  inactiveProfile: {
    notActivated:
      "This profile has not been activated. For more information please contact the administrators of this website.",
  },
  forgotPassSection: {
    signUp: "Don’t have an account yet?",
    register: "Register",
    wantToSignIn: "Want to sign in?",
    login: "Login",
    resetPass: "Reset Password",
    haveAnAccount: "Have an account?",
    signIn: "Sign in",
    forgotPass: "Forgot your password?",
    error: "An error occurred",
    email: "E-mail",
    sentEmail: "An e-mail was sent to",
    followLinkResetPass:
      "Follow the link in the e-mail to reset your password.",
    emailSent: "E-mail sent!",
    sendReset: "Send Reset E-mail",
    invalidEmail: "Invalid e-mail",
    emailRequired: "An e-mail address is required",
    passRequired: "Password is required",
    passMinRule: "Password must be at least 6-characters long",
    passMaxRule: "Password cannot be more than 16-characters long",
    emailPlaceHolder: "demo@prezp.com",
  },
  confirmEmailSection: {
    emailConfirmed: "E-mail Successfully Confirmed!",
    contWeb: "Continue to website",
    contApp: "Continue to app",
    wait: "Give us a sec...",
    error: "An error occurred",
    couldNotConfirm: "E-mail could not be confirmed...",
    backToLogin: "Back to Login",
  },
  registerDeviceSection: {
    deviceAuthentication: "Device Authentication",
    toActivate: "To activate this VR Device",
    step1: "1. On your computer or mobile device, go to:",
    step2: "2. Enter the following code:",
    step3:
      "3. Push continue once you have authenticated on your computer or mobile device:",
    continue: "Continue",
    wait: "Give us a sec...",
    error: "An error occurred",
    couldNotGetCode: "Code could not be requested...",
    couldNotAuthenticate:
      "Signup error, please check authentication on the 2nd device.",
    backToLogin: "Back to Login",
  },
  confirmDeviceSection: {
    deviceConfirmed: "Device successfully authorized!",
    deviceConnected: "Your device is now connected.",
    contApp: "Continue to app",
    deviceActivation: "Device Activation",
    enterCode: "Enter the code displayed on your device",
    oneTimeCode: "One-time code",
    confirmDeviceCode:
      "Please confirm this is the code displayed on your VR Headset:",
    notInitiated:
      "If you did not initiate this action or you do not recognize this device select cancel.",
    continue: "Continue",
    cancel: "Cancel",
    confirm: "Confirm",
    wait: "Give us a sec...",
    error: "An error occurred. Device could not be authorized.",
    couldNotConfirm: "Please check your activation code.",
    backToLogin: "Back to Login",
    codeRequired: "Code is required",
    codeMin: "Code must be at least {min} characters",
    codeMax: "Code can be a maximum of {max} characters",
  },
  audioSection: {
    startAnal: "Start Analysis",
  },
  scoreDetailsSection: {
    sessionScore: "Session Score",
  },
  sentimentSection: {
    breakdown: "Breakdown by sentence",
    positive: "Positive",
    negative: "Negative",
    neutral: "Neutral",
    noAnalyzedSentence: "No sentences were analyzed.",
    sentimentRatio: "Sentiment",
  },
  pausesCard: {
    pauses: "Pauses",
    spentSubVerb: "You spent",
    spentPrep: "of time in pause.",
    number: "Pauses Made",
    average: "Average Pause Time",
    total: "Total Pause Time",
    timeUnit: "seconds",
    warning:
      "The duration of a pause is at least 2 seconds. Low volume can be interpreted as a pause.",
    ppm: "Pauses / Minute",
    ppmCaps: "PAUSES / MINUTE",
  },
  movementRatioCard: {
    movementRatio: "Movement Ratio",
    headMovement: "Head Movement",
    handMovement: "Hand Movement",
    noMoveData: "No movement data found.",
  },
  managedUsersCard: {
    usersManaged: "Users you manage",
    noManagedUsers: "You do not manage any users",
  },
  keywordsCard: {
    keywords: "Keywords",
    basedOn: "Based on AI",
  },
  audioCard: {
    yourAudio: "Your audio",
    audioFileInfo:
      "This is the audio file you recorded for this session. Listen back to it and as you scrub through you will see the charts update",
  },
  volumeCard: {
    voiceVolume: "Voice Volume",
    avgVoiceVol: "Average Volume",
  },
  pitchCard: {
    voicePitch: "Voice Pitch",
    avgVoicePitch: "Average Pitch",
  },
  sentimentRatioCard: {
    sentimentRatio: "Sentiment",
  },

  appBar: {
    notConfirmedEmail: "You have not confirmed your e-mail. Please do so.",
    sending: "Sending...",
    sendEmail: "Send E-mail",
    emailSent: "E-mail Sent!",
  },
  toolbarMenu: {
    settings: "Settings",
    mail: "Mail",
    refresh: "Refresh",
    logout: "Logout",
  },
  profileDropdown: {
    loading: "Loading...",
    profile: "Profile",
    checkoutSettings: "Check out the settings",
    logout: "Logout",
  },
  groupName: {
    newName: "New Group Name",
    rename: "Rename",
    nameRule: "Name must be at least 3-letters long",
  },
  groupItem: {
    deleteGroup: "Delete group",
    areYouSure: "Are you sure you wish to delete ",
    deleteInfo: "This will delete all videos associated with it as well.",
    confirm: "Confirm",
    cancel: "Cancel",

    leave: "Leave group",
    join: "Join group",
    groupLeft: "You have left the group {name}",
    groupJoined: "You have joined the group {name}",
    confirmLeave: "Are you sure you wish to leave {name}?",
    confirmJoin: "Are you sure you wish to join {name}?",
  },
  addGroupCard: {
    addNewGroup: "Add A New Group",
    groupName: "Group Name",
    users: "Group Users",
    submit: "Submit",
    addGroup: "Add Group",
    publicGroup: "Public Group",
  },
  // Navigation panel
  nav: {
    dash: "Dashboard",
    sessions: "Sessions",
    list: "Session list",
    submitted: "Submitted sessions",
    progress: "Session progress",
    groups: "Groups",
    news: "News",
    goals: "Goals",
    meetings: "Meetings",
    company: "Company",
    howto: "Tutorials",
    presentation: "Presentation",
    admin: "Admin panel",
    sessionList: "Session List",
    sessionProgress: "Session Progress",
    seminars: "Seminars",
    seminarsDash: "Dashboard",
    seminarsProg: "Progress",
    seminarsAdmin: "Seminar Admin",
  },
  // New stuff
  global: {
    loading: "Loading",
    submit: "Submit",
    empty: "Empty",
    close: "Close",
    requiredf: "Field required",
    cancel: "Cancel",
    files: "Attached files",
    confirm: "Confirm",
    none: "None",
    back: "Back",
    done: "Done",
    save: "Save changes",
    refresh: "Refresh",
    reset: "Reset",
    clear: "Clear",
    nomatch: "No items that match your search",
    upload: "Upload",
    create: "Create",
    invalidURL: "Invalid URL",
    err: "An error occurred",
    retry: "Try again",
    backToLogin: "Back to login",
    backToSignUp: "Back to sign-up",
    contApp: "Continue to app",
    toApp: "PREZP App",
    backToDashboard: "Back to dashboard",
    imprint: "Imprint",
    dataprotection: "Privacy Policy",
    dataprotection_link:
      "https://prezpstore.blob.core.windows.net/site-files/EN_Datenschutzerkla%CC%88rung_08.11.2022.pdf?sp=r&st=2022-11-08T11:31:11Z&se=2032-11-08T19:31:11Z&spr=https&sv=2021-06-08&sr=b&sig=FrLKJIaWhi%2BXAI01m%2FvWdxWPfv5T1mxlc4PbNhs19nQ%3D",
    feedback: "For Feedback and Questions: info@prezp.com",
    nodata: "No data available",
    remove: "Delete",
  },

  forms: {
    invalidform: "This form is invalid",
    invalidfield: "This field is invalid",
    reqfield: "This field is required",
    audiofile: "Please provide an audio file",
    videofile: "Please provide a video file",
    namelen3: "Name must be at least 3 letters long.",
    file: "Please provide a file",
    invaliddata: "Invalid input data",
    errors: "Cannot continue with errors present",
    missing: "Some files were missing",
    contact: {
      title: "Contact",
      email: "Email",
      emailPlaceHolder: "demo@prezp.com",
      message: "Message",
      messagePlaceHolder: "Write a message",
      submit: "Send Message",
      invalidEmail: "Invalid e-mail",
      emailRequired: "E-mail is required",
      messageRequired: "Message is required",
      success: "Message was sent successfully",
      fail: "Message sending failed",
    },
  },

  upsess: {
    button: "Upload audio",
    title: "Upload a new session from an audiofile",
    subtitle: "Create new session",
    text:
      'Upload a session from an audio file. Uploaded sessions can be viewed in the "Sessions" page. Analysis starts right after you upload. Click refresh once in a while to check if your session\'s analysis is complete.',
    go: "Create now",
    hint:
      "We support the most common audio-file formats such as mp3, wav, ogg, m4a(*) and mpeg(*)",
    smallprint:
      "*in some rare cases the transcription for .m4a or .mpeg files cannot be created",

    select: "Select presentation",
    rec: "Record session",
    vrrec: "VR Recording",
    rectip: "Record a new session directly",
    rectext: "Record a new session, with or without a presentation",
    rejected_file:
      "This file format is not supported. only the following audio formats are allowed: mp3, wav, ogg, m4a, mpeg",
    errorHeading: "Error",
    videoTooBig:
      "Your video is too large (> 400 MB). Please reduce the resolution or keep your presentation shorter.",
    videoTooBigDownloadHint:
      "Hint: You may download the video using the three dots on the lower right corner in the video recorder to reduce the resolution.",
    uploading: "Uploading audio ...",
    uploadComplete: "Audio upload completed",
  },

  upsessv: {
    button: "Upload video",
    title: "Upload a new session from a videofile",
    text:
      "Upload a session from a video file. Analysis results will be displayed in the list of sessions. Analysis starts Analysis starts right after the upload completes.",
    hint: "We support only the mp4-videofile format",
    smallprint: "* In some cases we cannot process an uploaded video file",
    rejected_file:
      "Unsupported file type. Only the mp4 video format is allowed.",
    noText: "No spoken text could be recognized.",
    uploading: "Uploading video ...",
    uploadComplete: "Video upload completed",
  },

  transcription: {
    start: "Start",
    stop: "Stop",
    uploadRec: "Upload recording",
    nextSlide: "Next slide",
    resume: "Resume",
    pause: "Pause",
    audioOnly: "Audio Only",
    video: "Video",
    total: "Total",
    duration: "Duration: ",
    wpm: "Words per minute: ",
    occurrences: "Word occurrences: ",
    slides: "Slides",
    slide: "Slide",
    wpmLower: " words per minute",
    transAudio: "Transcribed audio",
    awaitingText: "Waiting for text recognition...",
  },

  quota: {
    title: "PREZP Quota",
    fast: "Fast",
    relaxed: "Relaxed",
    upgrade: "Upgrade Plan",

    tip: {
      liveTranscription:
        "Your remaining quota for Live Transcriptions (in seconds)",
      analysis: "Your remaining quota for Session Analysis",
      aiFeedback: "Your remaining quota for AI-Feedbacks",
    },
  },

  recording: {
    maxTimeWarning: {
      heading: "Recording Limit",
      maxAllowedTime: "{minutes} minutes",
      maxAllowedHint:
        "TED Style Talks: The maximum duration for session recording is {0}. For an optimal presentation, we recommend a target time of {1}.",
      confirm: "Lets Go",
      cancel: "Not ready",
      dontAskAgain: "Do not show again",
    },
    noCompatibleDevice: "No compatible devices found.",
    noDevicePermission:
      "Camera and microphone permissions are required for recording.",
    noMicrophonePermission:
      "Microphone permissions are required for recording.",
  },

  alerts: {
    beforeLeaveRecording:
      "Do you really want to leave? The recording will be canceled!",
    beforeLeaveUpload:
      "Do you really want to leave? The upload will be canceled!",
  },

  groups: {
    groups: "Groups",
    public: "Public Groups",
    all: "All groups",
    none: "You are not part of any group yet",
    hint: "Separate e-mails with comma (,) or by pressing tab or enter",
    created: "Created at",
    delete: "Delete group",
    you: "(you)",

    creator: "Group creator",
    joined: "Joined",
    removeu: "Remove user",
    confrem: "Are you sure you wish to remove {u} from the group?",
    remself: "yourself",

    videos: "Latest videos",
    addv: "Add video",
    addnv: "Add new video",
    vidtitlehint: "If the title is empty, it will be set automatically",
    vidtitlelabel: "Video title",
    viddesc: "Video description",
    vidpinlabel: "Pin this video?",
    vidpinhint: "The pinned video will appear first",
    link: "Link",
    vidlinklabel: "Video link",
    file: "File",
    novideos: "No videos found",
    delv: "Delete video",
    editv: "Edit video",
    original_title: "Original title",
    author: "Author",
    uploaded_by: "Uploaded by",

    posts: "Latest posts",
    addp: "Add post",
    addnp: "Create new post",
    posttitle: "Post title*",
    postbody: "Post body*",
    postpin: "Pin this post?",
    bannerlabel: "Upload banner image?",
    bannerfile: "Banner image",
    sendEmail: "Send this post as email?",
    includeInvited:
      "Email users, which have not yet accepted their invitation?",
    files: "Other files",
    noposts: "No posts found",
    delpost: "Delete post",
    editpost: "Edit post",

    users: "Group members",
    addu: "Add member",
    addnu: "Add member to group",
    adddexcel: "Add member from excel worksheet",
    upload: "Upload",
    missingexcel: "No excel to upload",
    uemail: "User e-mail",
    makeman: "Make manager",
    remman: "Remove as manager",
    nousers: "No user e-mails that include {term}",

    inv: {
      title: "Your invitations",
      message: "{inviter} invited you to group {group}",
      accept: "Accept",
      decline: "Decline",
      accepted: "You were added to the group {group}",
      declined: "Invitation from {inviter} declined",
      sent: "Sent invitations",
      nonesent: "No invitations sent",
    },
  },

  inv: {
    title: "Your invitations",
    message: "{inviter} invited you to group {group}",
    accept: "Accept",
    decline: "Decline",
    accepted: "You were added to the group {group}",
    declined: "Invitation from {inviter} declined",
    sent: "Sent invitations",
    nonesent: "No invitations sent",
    asmanager: "Invited as manager",
    asemployee: "Invited as employee",
  },

  videos: {
    videos: "Videos",
    none: "No videos in that group",
    edit: "Edit video",
    delete: "Delete video",
    desc: "Video description",
    title: "Video title",
    ispinned: "Is pinned?",
  },

  goals: {
    goals: "Your goals",
    none: "You have no goals",

    assigned: "Assigned goals",
    tassigned: "All goals that were assigned to you",
    noassigned: "No assigned goals",
    assigner: "From",

    created: "Created goals",
    tcreated: "All goals that were created by you",
    nocreated: "No created goals",

    add: "Add goal",
    complete: "Complete {n} sessions",
    aim: "Aim for",
    addc: "Add condition",
    assign: "Assign to user",
    assignedu: "Assigned users",
    assigny: "Assign yourself?",
    submit: "Submit goal",
    remove: "Remove goal",
    select: "Select all",
    deselect: "Deselect all",
    cond: "Condition",
    conds: "Goal conditions",
    ctype: "Condition type",

    score: "Target score",
    due: "Due date",
    sentiment: "Target sentiment",
    pitch: "Target pitch",
    volume: "Target volume",
    pauses: "Target pauses",
    wpm: "Target speech speed",
    overdue: "Overdue",
    filler: "Target filler words score",
    clarity: "Target speech clarity score",
    repetition: "Target repetitions score",
    overall: "Target overall score",

    condtype: {
      date: "Due Date",
      sent: "Sentiment",
      pitch: "Average Pitch",
      vol: "Average Volume",
      ppm: "Pauses per Minute",
      wpm: "Speech Speed",
      filler: "Filler words",
      clarity: "Speech clarity",
      repetition: "Repetitions",
      overall: "Overall score",
    },

    condmsg: {
      overdue: "Overdue",
      duein: "Due",
      session_score: "Only sessions with score greater than {n} count",
      sentiment: "Aim for a sentiment score between {n1}% and {n2}%",
      pitch: "Aim for an average voice pitch between {n1}Hz and {n2}Hz",
      volume: "Aim for an average volume between {n1}% and {n2}%",
      pauses: "Aim to make anywhere between {n1} and {n2} pauses per minute",
      speech_speed: "Aim for {n1} to {n2} words per minute",
      filler_words:
        "Aim for a score of {n} or higher in the 'Filler Words' category.",
      speech_clarity:
        "Aim for a score of {n} or higher in the 'Speech Clarity' category.",
      overall_score:
        "Aim for a score of {n} or higher in the 'Overall' category.",
      repetition:
        "Aim for a score of {n} or higher in the 'Repetition' category.",
    },

    confirm: "Are you sure?",
    confirmmsg:
      "Are you sure you wish to delete this goal? The action is irreversible.",
  },

  news: {
    news: "News",
    read: "Read Article",
    all: "Read all",
    posted: "Posted on",
  },

  meet: {
    created: "Created by:",
    expired: "This meeting has expired",
    add: "Add meeting",
  },

  pres: {
    pres: "Presentations",
    none: "You have no presentations",
    dl: "Download",
    ed: "Edit",
    rm: "Remove",
    edit: "Edit Presentation",
    no_slides:
      "If you're not seeing images, then the presentation could not be converted",
    tip: "Upload your presentations (.pdf) here to use on your VR Headset",
  },

  rec: {
    tiptitle: "How to use the built-in recorder",
    tiptext:
      "You can test your microphone volume to determine if it is within the best range for audio analysis.",
    hide: "Don't show this tip again.",
    voll: "Too quiet",
    volgood: "Good",
    volh: "Too loud",
    latest: "Latest recordings",
    save: "Save recording as",
    sess: "New session",
    base: "Add as baseline",
    succ: "Session Created Successfully",
    fromVideo: "Session from Video created successfully",
    fail: "Could not create session",
    remove: "Remove recording",
    removetext: "Are you sure you wish to remove the following recording?",
    mic: "Adjust mute gain",
    mute: "Mute/unmute",
    devices: "Devices",
    microphones: "Microphones",
    cameras: "Camera",
    prefix: "Recorded on",
  },

  meta: {
    dev: "Deviation",
    earlier: "Earlier sessions average",
    recent: "Recent sessions average",
    names: {
      pitch: "Pitch",
      volume: "Volume",
      pauses: "Pauses",
      sentiment: "Sentiment",
      session_score: "Session score",
      speech_speed: "Speech speed",
    },
    units: {
      pitch: "Hz",
      volume: "%",
      pauses: "pauses/minute",
    },
  },

  slides: {
    slides: "Slides",
    tip: "Slides from presentation",
    pitch: "Average pitch",
    volume: "Average volume",
    wpm: "Words/Minute",
    start: "Start time",
    end: "End time",
    duration: "Duration",

    notfound: "No slide that matches the current time",
    none: "There are no slides for this session",
  },

  lookup: {
    title: "Look Up Words",
    enter: "Enter text to filter by",
    exact: "Exact match",
    show: "Show all",
    hide: "Show less",
    start: "start typing...",
    filter: "Filter by",
    missing: "Not enough data to show lookup words",
  },

  cards: {
    avg: "Average",
    pvolume: "Personal volume",
    ppitch: "Personal pitch",
    wpm: "Words/Minute",
    keywords: "Keywords (Based on AI)",
  },

  bench: {
    choose: "Choose benchmark",
    change: "Change benchmark",
    title: "Benchmark session",
    text:
      "Choose a benchmark session to be displayed on the same graphs. You can use to benchmark session to compare your performance to it.",
  },

  sentences: {
    filter: "Sentence filter",
    none: "No sentences found",
    pos: "Positive",
    neu: "Neutral",
    neg: "Negative",
    nochart: "No sentiment data found",
    filters: {
      none: "None",
      vpos: "Very positive",
      vneg: "Very negative",
    },
  },

  subscription: {
    title: "Subscriptions",
    menutitle: "Your subscriptions",
    activesub: "Active subscription",
    noactivesub: "No subscription",
    plans: "Select plan",
    priceInterval: "Price / interval",
    nextPayment: "Next payment due on",
    subscriptionEnds: "Subscription ends on",
    lastPayment: "Last payment",
    paymentMethod: "Payment method",
    subscriberSince: "Subscriber since",
    canceledOn: "Canceled on",

    latestInvoice: "Latest invoice",
    viewAll: "View All",
    invoiceNr: "Invoice#",
    status: "Status",
    downloadInvoice: "Download Invoice",

    active: "Active",
    paid: "Paid",

    monthly: "monthly",
    yearly: "annually",
    monthlyPayment: "Monthly payment",
    yearlyPayment: "Annual payment",
    month: "month",
    year: "year",
    perMonth: "per month",
    perYear: "per year",
    desctitle: "Description",
    payment: "payment",

    paymentTo: "Payment to",
    selectedPlan: "Selected plan",
    subFor: "Subscription for",
    billing: "Billing details",
    checkout: "Checkout",

    buyAsCompany: "Purchase as a company",
    vatData: "VAT data",
    enterCompanyName: "Company Name",
    companyNameRequired: "Your company name is required.",
    companyNameMin: "Your company name is too short.",
    enterVatId: "VAT-ID",
    vatIdRequired: "Your VAT ID is required.",
    vatIdInvalid: "Your VAT ID is invalid.",
    confirmVatId: "Confirm VAT ID",
    couldNotValidate: "Could not validate VAT ID number.",
    vatIdConfirmed: "VAT ID successfully confirmed.",
    vatIdValidated: "Your VAT ID has been validated.",
    vatServiceDown:
      "The VAT ID validation service is currently unavailable. Please try again later.",
    vatServiceOverload:
      "The VAT ID validation service is currently overloaded. Please try again later.",
    companyIncomplete: "The company details are incomplete.",
    reverseCharge:
      "Reverse charge: Tax liability lies with the recipient of the service.",

    product: "Product",
    cycle: "Cycle",
    duration: "Duration",
    price: "Price",
    netTotal: "Net Total",
    vat: "VAT",
    total: "Total",
    coupon: "Coupon",
    redeem: "Redeem",
    enterCode: "Coupon Code",
    redeemed: "redeemed",
    remove: "Remove",
    discountMonths:
      "* Coupon applies for the first {d} months, the standard price of the selected plan then applies.",
    discountForever: "* Coupon applies until the subscription is canceled.",
    forever: "forever",
    legalNotice:
      "By confirming your subscription, you permit 111 Medien Service GmbH to charge future payments to your payment instrument in accordance with the terms and conditions. You can cancel your subscription at any time.",

    cancel: "Cancel",
    back: "Back",
    next: "Next Step",
    purchaseSubscription: "Purchase Subscription",

    pricing: {
      title: "Choose your PREZP plan",
      description:
        "PREZP offers a wide range of different pricing plans to perfectly suite your needs. For individuals and at enterprise scale.",
      fetching: "Fetching Prices...",
      orContinueFree:
        "Or continue with the free version to explore PREZP with limitations.",
      continueFree: "Continue with PREZP free",
      compare: "Compare pricing plans",
    },

    cancelSubscription: "Cancel subscription",
    areYouSure: "Are you sure you want to cancel your subscription?",
    canceled: "Subscription Canceled",
    youCanceled:
      "You have canceled your subscription. You will continue to have all the benefits of your remaining quota until your subscription is permanently deleted.",
    toSubscribeAgain:
      "To resubscribe to PREZP you must wait until the end of the current payment cycle.",
  },

  checkout: {
    success: {
      statement: "Thank You",
      message: "Your PREZP plan order was completed successfully",
      contact:
        "An e-mail including the details about your order \n" +
        "has been sent to the e-mail address provided.",
      action: "Start",
      updating: "User data is being updated...",
    },
    error: {
      statement: "Payment Error",
      message:
        "We are sorry. Your payment could not be processed. \n" +
        "Please try again.",
      contact: "If this problem persists, please contact our Support Team.",
      action: "Go Back",
    },
    pending: {
      statement: "Payment Pending",
      message:
        "Your payment is still processing or requires additional confirmation. \n" +
        "Please check your inbox for further instructions.",
      contact:
        "If the payment can't be completed, please contact our Support Team.",
      action: "Go Back",
    },
  },

  tier: {
    billedMonthly: "Billed monthly",
    billedYearly: "Billed annually",
    billedCustom: "Depending on your needs",
    individual: "Individual",
    savePercentYearly: "Save {percent}% with annual billing",
    saveAmountYearly: "Save {currency} {amount} with annual billing",
    subscribe: "Subscribe",
    contactSales: "Contact Sales",

    badge: {
      explore: "To explore PREZP", // free
      casual: "For casual training", // basic
      recommended: "Recommended for most users", // standard / basic(mvp)
      business: "For business training", // premium
    },
  },

  features: {
    // single features - keep in sync with permission scope naming
    relaxed_live_transcription:
      "Relaxed Live-Transcriptions ({hours}h / month)",
    fast_live_transcription: "Fast Live-Transcriptions ({hours}h / month)",

    relaxed_analysis: "{count} Relaxed Scored Analyzes",
    fast_analysis: "{count} Fast Scored Analyzes",

    relaxed_ai_feedback: "{count} Relaxed AI Feedbacks",
    fast_ai_feedback: "{count} Fast AI Feedbacks",

    limited_public_group: "Limited Access to Public Groups",
    full_public_group: "Access to Public Groups",
    b2b_group: "Access to B2B Groups",

    basic_tutorial: "Basic Tutorials",
    advanced_tutorial: "Advanced Tutorials",
    expert_tutorial: "Expert Tutorials",

    coach_review_booking: "Human-Coach™ Review Bookings",
    coach_1on1_booking: "Human-Coach™ 1-on-1 Bookings",

    seminar: "Access to Seminars",
    challenge: "Access to Challenges",
    course: "Access to Courses",
    vr_mode: "PREZP VR Access",
    sso: "Single Sign On",
    custom_idp: "Custom IDP Integration",
    external_api: "External API Integration",
    onboarding: "On-Boarding & Training",
    tech_support: "Dedicated Tech Support",
    dev_support: "Dedicated Developer Support",
    sla: "SLA + With 99% Uptime",
    redundant_service: "Redundant Services",

    // feature combos - just used for compact lists
    everything_basic: "Includes everything from Basic Plan",
    everything_premium: "Includes everything from Premium Plan",

    unlimited_relaxed_hours_and_workers: "Unlimited Relaxed Hours & AI Workers",
    unlimited_fast_hours_and_workers: "Unlimited Fast Hours & AI Workers",

    basic_tutorial_and_challenge: "Basic Tutorials & Challenges",
    advanced_tutorial_and_challenge: "Advanced Tutorials & Challenges",
    expert_tutorial_challenge_and_course:
      "Expert Tutorials, Challenges and Courses",

    seminar_and_public_group: "Access to Seminars & Public Groups",
    seminar_public_and_b2b_group: "Access to Seminars, Public & B2B Groups",

    sso_and_custom_idp: "SSO & Custom IDP Integration",
    dev_tech_support: "Dedicated Developer/Tech Support",

    sla_and_redundant_service: "SLA + Redundant Services",
  },

  company: {
    title: "Company",
    partof: "You are part of a company",
    notpartof: "You are not part of a company",
    create: "Feel free to create a company",
    createbtn: "Create company",
    invitations:
      "Or, you may accept any of the following invitations you have received to join an existing company",
    noinv: "No invitations",

    companies: "Companies",
    getting: "Getting companies...",
    notfound: "No companies found",

    none: "You do not have a company",
    inactive: "This company has not been activated",
    pdesc: "Click here to select a plan for purchasing a subscription",

    plans: "Plans",
    changep: "Change plan",
    selectp: "Select new plan",
    same: "Select a different plan",

    emp: "Employees",
    esingle: "Employee",
    invite: "Invite user(s)",
    adde: "Add employee",
    eemail: "User e-mail",
    makeman: "Make manager?",
    rem: "Remove",
    sure: "Are you sure you wish to remove this employee?",
    crole: "Change role",
    crolefor: "Change role for",
    noemp: "No company employees yet",

    edit: "Edit company name",
    name: "Company name",
  },

  charts: {
    avghand: "Average hand movement",
    avghead: "Average head movement",
    ppm: "Pauses/minute",
    sent: "Sentiment",
    pitchot: "Pitch over time",
    scoreot: "Score over time",
    avgvp: "Average voice pitch",
    avgvol: "Average voice volume",
    avgwpm: "Average words/minute",
    sscore: "Session score",
    points: "Show {n} points",
    all: "all",
  },

  sess: {
    noneselected: "No session selected",
    shared: "Shared with {n} users",
    sharedWithOne: "Shared with {n} user",
    sharedWith: "Shared with ",
    sharedWithNone: "Shared with no one",
    noOneToShareWith:
      "You can share your sessions only with users in one of your groups.",
    private: "Private",
    duration: "Duration",
    none: "No sessions found",
    noneseelcted: "No session selected",
    edit: "Edit session",
    name: "Session name",
    vis: "Visibility",
    updated: "Session updated",
    filter: "Filter sessions",
    keywords: "Keywords",

    sharing: "See shared Sessions",
    usersSharing: "Users that are sharing sessions with you",
    usersManaged: "Users you're managing",
    otherUsers: "Other users from groups",
    changeuser: "Change user",
    nouser: "No user selected",
    sharedback: "Back to shared sessions",

    list: "List",
    progress: "Progress",
    progresslimit: "Choose number of sessions to show",

    filters: {
      date: {
        before: "Before date",
        after: "After date",
        between: "Between two dates",
      },
      duration: {
        longer: "Longer than (minutes)",
        shorter: "Shorter than (minutes)",
        between: "Time span (minutes)",
      },
      name: {
        startsWith: "Starts with",
        endsWith: "Ends with",
        includes: "Includes",
        exact: "Exact match",
      },
      score: {
        greater: "Greater than",
        less: "Less than",
        range: "Score range",
      },
      pres: {
        title: "Filter by presentation",
        label: "Select presentation",
      },
    },

    hanging: {
      title: "Other users",
      text:
        "You're still sharing this session with some users, with whom you are no longer in any group.",
      removeOneDialogTitle:
        "Are you sure you with to stop sharing this session with {u}",
      removeOneDialogText:
        "This action is irreversible. You'll need to be in at least one group with {u} to be able to share a session with them again later.",
      removeAllButton: "Remove all such users",
      removeAllDialogTitle: "Are you sure you want to remove all such users?",
      removeAllDialogText:
        "This action is irreversible. You'll need to be in at least one group with each of these users to be able to share a session with them again later",
      snack: {
        removeAll: "Success. Users removed",
        removeSome:
          "Not all users were removed. Users {e-mails} could not be removed",
        removeFail: "Could not stop sharing session with one or more users",
      },
    },

    clear: {
      title: "Remove all users",
      text: `You'll still have to confirm your action by clicking the "Confirm" button`,
    },
  },

  words: {
    word: "word",
    filler: "Filler word",
    stop: "Stop word",
    normal: "Default word",
  },

  admin: {
    activeProduct: "Active product",
    companies: "Companies",
    coupons: "Stripe coupons",
    createNewProduct: "Create new product",
    createProduct: "Create product",
    maximumAllowedEmployees: "Maximum allowed employees",
    page: "Admin page",
    productDescription: "Product description",
    productName: "Product name",
    productPrice: "Product price (in Euro)",
    products: "Stripe products",
    recorder: "Dev-Recorder",
    slides: "Presentation slides",
    tuts: "Tutorials",
    transcription: "Transcription",
    uploadTooltip: "Upload excel file with users",
    users: "Users",
    usersPageHeader: "Users",
    verifyEmail: "Verify this user's e-mail",
  },

  permissions: {
    editTitle: "Manage permissions",
    editText: "Add, remove or extend permissions for",
    removeTitle: "Are you sure?",
    removeText:
      "You can choose to add the same permission type at a later date.",
    modifyTitle: "Update counter",
    add: "Add",
    confirm: "Confirm",
    inherit: "Inherit from",
    counter: "Remaining number",
    seconds: "Remaining seconds",

    seminar: "Seminars",
    upload_audio: "Upload audio",
    upload_video: "Upload video",
    session_training: "Session Training",
    can_share_with_all: "Can share sessions with all users",
    teacher: "Lecturer Access",
    student: "Student Access",
    live_transcription_relaxed: "Relaxed live transcription",
    live_transcription_fast: "Fast live transcription",
    analysis_relaxed: "Relaxed analysis",
    analysis_fast: "Fast analysis",
    ai_feedback_relaxed: "Relaxed AI feedback",
    ai_feedback_fast: "Fast AI feedback",
    public_group_limited: "Limited public group",
    public_group_full: "Full public group",
    b2b_group: "B2B group",
    tutorial_basic: "Basic tutorial",
    tutorial_advanced: "Advanced tutorial",
    tutorial_expert: "Expert tutorial",
    coach_booking_review: "Coach review booking",
    coach_booking_1on1: "Coach 1-on-1 booking",
    challenge: "Challenges",
    course: "Courses",
    vr_mode: "VR mode",
    sso: "Single Sign-On (SSO)",
    custom_idp: "Custom Identity Provider (IDP)",
    external_api: "External API",
    onboarding: "Onboarding",
    support_tech: "Tech support",
    support_dev: "Dev support",
    sla: "Service Level Agreement (SLA)",
    redundant_service: "Redundant service",
  },

  roles: {
    editTitle: "Manage roles",
    editText: "Add or remove roles for user",
    removeTitle: "Are you sure?",
    removeText: "You can choose to add the same role at a later date.",
    modifyTitle: "Change date",
    add: "Add",
    confirm: "Confirm",

    subscriberFree: "Subscriber Free",
    subscriberBasic: "Subscriber Basic",
    subscriberStandard: "Subscriber Standard",
    subscriberPremium: "Subscriber Premium",
    subscriberEnterprise: "Subscriber Enterprise",
    employee: "Employee",
    manager: "Manager",
    owner: "Owner",
    admin: "Admin",
    user: "User",
  },

  feature_perms: {
    editTitle: "Manage permissions",
    editText: "Add, remove or extend permissions for",
    removeTitle: "Are you sure?",
    removeText:
      "You can choose to add the same permission type at a later date.",
    modifyTitle: "Change date",
    add: "Add",
    confirm: "Confirm",
    analysis_relaxed_count: "Relaxed analysis count",
    analysis_fast_count: "Fast analysis count",
    ai_feedback_relaxed_count: "Relaxed AI feedback count",
    ai_feedback_fast_count: "Fast AI feedback count",
    live_transcription_relaxed_seconds: "Relaxed live transcription seconds",
    live_transcription_fast_seconds: "Fast live transcription seconds",
  },

  activeUser: {
    search: "Search users",
    none: "No users found",
    indef: "Indefinite",
    mins: " minutes",
    hours: " hour(s)",
    days: " day(s)",
    months: " month(s)",
    both: "Active/Deactivated",
    active: "Active",
    inactive: "Deactivated",
    activate: "Activate",
    deactivate: "Deactivate",
    change: "Change active status for user ",
    until: "until",
    confirm: "Are you sure you want to deactivate this user?",
    activateMsg:
      "You are about to activate this user. Please select how long this user will remain active.",
  },

  def_pres: {
    title: "Default presentation slides",
    current: "Currently uploaded slides",
    currentmsg:
      "These are the slides every new user gets when they register for PREZP",
    none: "No default presentation has been set",
    upload: "Upload new presentation",
    uploadBtn: "Upload",
    remove: "Delete default presentation",
    removeBtn: "Remove presentation",
    confirm: "Are you sure you wish to remove the default slides?",
    no_slides: "This presentation could not be converted or is empty",
  },

  snack: {
    users: {
      rupdated: "User role updated",
      rem: "User removed",
      emails: "One or more e-mails were invalid",
      alreadyin: "One or more users are already in the group",
    },
    comp: {
      created: "Company successfully created",
      createfail: "Could not create company",
      invited: "User successfully invited",
      rupdated: "User role updated",
      upsuccess: "Upgrading successful",
      upfail: "Upgrading did not succeed",
      empmax: "Employees exceeds max number",
      rejected: "Following users couldn't be added: ",
      success: "Invitation successfully sent",
      conflict: "One or more users are already in a company",
    },
    groups: {
      unadded: "These users could not be added: ",
      del: "Group deleted successfully",
      added: "Group added successfully",

      ingroup: "User already in group",
      allin: "All users already in group",
      somein: "Some users are already in this group: ",
    },
    news: {
      added: "Article successfully deleted",
    },
    pres: {
      del: "Successfully deleted presentation",
      cannotDelete:
        "You cannot delete this presentation, you already recorded a session from it.",
    },
    sess: {
      delsuccess: "Successfully deleted session",
      delfail: "Could not delete session",
      metafail: "Could not update meta analysis settings",
      benchfail: "Could not fetch benchmark sessions",
      basefail: "Could not upload baseline sessions",
      fetchfail: "Could not fetch sessions for {e}",
    },
    vid: {
      editsuccess: "Successfully edited video",
      editfail: "Error editing video",
      delsuccess: "Successfully deleted video",
      delfail: "Error deleting video",
    },
    auth: {
      fail: "An error occured, redirecting to login",
      confirmfail: "Could not verify this e-mail",
      sent: "E-mail sent",
      sendfail: "Could not send e-mail",
      reset: "Successfully reset password",
      resetfail: "Could not reset password",
      deviceConfirmFail: "Could not verify the device",
    },
    stripe: {
      elempty: "Element cannot be empty",
      elinvalid: "Invalid payment information",
      elincomplete: "Incomplete data",
      purchase: "Success",
      pfail: "Could not purchase subscription",
      entercode: "Please enter a coupon code",
      codeinvalid: "Invalid coupon code",
      subscribing: "Setting your subscription up...",
      subGetError: "Error retrieving subscription",
      subCancelError: "Error canceling subscription",
    },
    meta: {
      attrs: "Please select at least one attribute",
    },
  },

  settings: {
    title: "User settings",
    pfp: "Profile picture",
    dark: "Enable dark mode",
    alreadyup: "You've already uploaded one or more baseline sessions.",
    analyzed: "Sessions have been analyzed.",
    notanalyzed: "Sessions have not been analyzed yet.",
    upAt: "Uploaded at",
    avgVol: "Average volume",
    avgPitch: "Average pitch",
    actions: "Actions",
    items_per_page_text: "Rows per page",
  },

  baseline: {
    up: "Upload baseline session",
    base: "Baseline Sessions",
    base1: "Baseline Session (1)",
    base2: "Baseline Session (2)",
    base3: "Baseline Session (3)",
    text:
      "This is going to be the baseline session, used to compare your future sessions to. You will be asked to read out a certain paragraph a few times in different ways",
    lang: "Choose language",
  },

  move: {
    title: "Movement Analysis",
    none:
      "You have not yet completed any VR training. There is no motion data to display.",
    head: {
      title: "Head movement",
      avg: "Average head movement",
      tip: "Shows head movement in the VR during presentation",
    },
    hand: {
      title: "Hand movement",
      avg: "Average hand movement",
      tip: "Shows hand movement in the VR during presentation",
    },
    ratio: {
      title: "Movement ratio",
      tip: "This shows the ratio between head and hand movement",
    },
  },

  number: {
    toosmall: "Number is too small (min.: {n})",
    toobig: "Number is too big (max.: {n})",
  },

  seminars: {
    dash: {
      title: "Seminar Dashboard",
      noSeminars: "No Seminars found",
      adminLink: "Admin Page",
      progressLink: "Progress",
      yourSubmissions: "Your submissions",
      feedback: "Feedback for me",
      submissions: "Submissions",
      noSubmissions: "No submissions yet",
      seeAllButton: "See all",
      participants: "Participants",

      edit: "Edit",
      view: "View",
      publish: "Publish",
      form: {
        publish: "Publish this seminar",
        title: "Title",
        description: "Description",
        lecturer: "Lecturer",
        price: "Price",
        success: "Successfully published seminar",
        fail: "There was an error while publishing this seminar",
      },
    },
    admin: {
      title: "Seminar Admin Panel",
      addBtn: "Add Seminar",
      addTitle: "Add new seminar",
      addText:
        "A seminar can be constructed here. Course elements such as fill-in-the-blanks, video feedback exercises or other course components can be created and added easily and flexibly. Afterwards, publish the seminar and invite your participants with one click.",

      feedback: {
        title: "Give feedback to user",
        requested: "Feedback requested",
        desc:
          "The following video submissions have requested feedback from the seminar owner",
        yourFeedback: "Your feedback",
        alreadyGiven: "You have already given feedback to this video.",
      },
      participants: {
        title: "Seminar Participants",
        removeAllUser: "Remove all user and submissions",
        removeAll: "Remove all submissions",
        filterUsers: "Filter users",
        refreshInv: "Refresh invitations and users",
        search: "Search",
        areYouSureTitle: "Are you sure",
        areYouSureAllUserText:
          "Are you sure you wish to clear all submissions of all users? They will have to restart the entire seminar.",
        areYouSureAllSubs:
          "Are you sure you wish to remove all submissions of this user",
        forThisSeminar: "for this seminar?",
        removeThisUser: "Remove this user",
        clearUserSubs: "Clear this user's submissions",
        removeAllUsers: "Remove all users",
        areYouSure: "Are you sure?",
        removeUsersAndSubs:
          "Are you sure you wish to remove all users and their submissions? This will effectively reset the seminar.",
        detail: "Aufzeichnungen von",
        numSubmissions: "Number of submissions",
        noParticipants: "No participants",
        pos: "Position",
        progress: "Progress",
        name: "Name",
        fetchFail: "Could not fetch all users",
        userNotFound: "User not found",
        emptySub: "Nothing to see. Empty submission.",
        filter: {
          title: "Filter users in this seminar",
          userCount: "{n} Users in total",
          progressLabel: "Target Current Progress",
          progressHint:
            "All users that have a progress percentage higher than or equal to this value will be included",
          blockLabel: "Target Current Block",
          blockHint: "All users that are past this block will be included",
          elLabel: "Target Current Element",
          elHint: "All users that are past this element will be included",
        },
      },
      invitations: {
        fetchFail: "Could not retrieve sent invitations",
        revokeFail: "Could not revoke invitation",
        invalidEmail: "Invalid email detected",
      },
      seminarList: {
        yourSemis: "Your seminars",
        participantOrOwnerOf: "You are participant or owner of {n} in total",
      },
    },

    edit: {
      actions: "Submissions",
      invite: "Invite users",
      inviteTitle: "Invite users to this seminar",
      inviteEmails: "E-mails that will be invited",
      editBtn: "Edit seminar",
      editTitle: "Edit seminar",
      blocks: {
        title: "Seminar blocks",
        addBtn: "Add block",
        editBtn: "Edit block",
        blockTitle: "Block title",
        blockDesc: "Block description",
      },
      elements: {
        title: "Elements in this block",
        addBtn: "Add element",
        editBtn: "Edit element",
        elTitle: "Element title",
        elDesc: "Element description",
        uploadVid: "Upload new video",
        embedVid: "Embed video",
        mkvFilesNo: "Warning: MKV files will NOT work!",
        file: "Video file",
        currentVideo: "Currently uploaded video",
        uploadingWillReplace:
          "Uploading a new video will replace the existing one and the existing one will be deleted",
        link: "Link to video",
        desc_text: "Put stuff here...",

        video_presentation: "Users will record a video, nothing to specify",
        give_feedback:
          "Users will give feedback to an uploaded video, nothing to specify here",
        watch_feedback:
          "Users will watch a feedback video made for them, nothing to specify here",
        gap_text: {
          title: 'Specify blanks by putting down "__" (double underscore)',
          answers: "Fill in the blanks (sample solution)",
          gapTexT: "Gap text",
        },
        addRow: "Add row",
        addCategory: "Add category",
        newCategory: "New category",
        categoryTitle: "Category title",
        deleteElement: {
          title: "Delete element",
          detail: "Are you sure you wish to delete this element?",
        },
        rateable: "This element's submissions are shared and can be rated",
        embed_youtube:
          "If you are linking youtube videos make sure they are able to be embedded",
        required_numer_of_submissions:
          "Required number of submissions for this element",
        maximum_number_of_submissions:
          "Maximum number of submissions for this element",
        number_range_1_100: "Number range is 1-100",
        number_range_0_10: "Number range is 0-10",
        max_condition:
          "Max must be greater than or equal to the required number submissions",
        required_number_of_shared_submissions:
          "Required number of SHARED submissions for this element",
        shared_number_condition:
          "Required number of shared submissions must be less than or equal to the number of required submissions",
        max_number_of_shared_submissions:
          "Maximum number of SHARED submissions for this element",
        timed_video_recording: "Timed video recording",
        timers: "Timers",
        exp_len: "Expected length of recording (in seconds)",
        max_len: "Maximum length of recording (in seconds)",
        range100: "Number range is 0-100",
        range10: "Number range is 1-10",

        max_shared_rule_1:
          "Max. number of shared submissions must be greater than or equal to required number of shared submissions",
        max_shared_rule_2:
          "Max. number of shared submissions must be less than or equal to required number of shared submissions",
        max_shared_rule_3:
          "Max. num. of submissions must be greater than the number of required submissions and in the range 1-100",
        max_shared_rule_4:
          "Max. num. of shared submissions must be greater than or equal to required number of shared submissions and in the range 1-10",
        req_subs_rule_1:
          "Required number of submissions must be greater than the number of shared submissions and in the range 0-10",
        req_shared_rule_1:
          "Required num. of shared submissions must be less than or equal to the number of required submissions and in the range 0-10",
        watch_video_rule:
          "This element type requires a video. Please link a YouTube video or upload a file.",
      },
      participants: {
        title: "Seminar Participants",
        detail: "Viewing submissions for",
        numSubmissions: "Total submissions",
        noParticipants: "No Participants",
      },
    },
    home: {
      startTut: "Start Tutorial",
      loading: "Loading...",
      noSemisFound: "No Seminars found",
      yourSemis: "Your seminars",
    },

    name: "Seminar name",
    description: "Seminar description",
    loading: "Loading seminar...",
    notFound: "Seminar not found",
    noBlocks: "This seminar has no blocks yet.",
    progress: "Seminar progress",
    tasks: "Tasks",
    skipTask: "Skip task",
    prev: "Previous",
    next: "Next",
    percentComplete: "{percent}% complete",
    submittedOn: "Submitted on",
    yourSubmission: "Your submission",
    yourAnswer: "Your answer",
    sampleSolution: "Sample solution",

    submissions: {
      videoRecorder: {
        uploadDialogTitle: "Upload your recording",
        uploadDialogText: "Upload your video recording.",
        askForFeedback: "Ask for feedback",
        askForFeedbackInfo:
          "If you ask for feedback, the seminar owner will see your video and can give feedback to it.",
        expectedTime: "Expected time:",
        maxAllowedTime: "max. recording time:",
        title: "Video recorder",
        actions: "Actions",
        start: "Record",
        stop: "Stop",
        upload: "Analyze",
        uploadFeedback: "Submit feedback",
        reset: "Discard",
        pause: "Pause",
        resume: "Resume",
        flipVideo: "Flip Video",
        nameYourFile: "Name your file",
        save: "Save",
        uploadYourPres: "Upload your presentation",
        privateVid: "Private video",
        sharedVid: "Shared video",
        wouldLikeFeedback: "Yes, I would like to request feedback from owner",
        shared:
          "The recording will be shared to all other seminar participants. Are you sure you want to do this?",
        notShared:
          "The following video will be saved as a submission to the current element of this seminar",
      },
      giveFeedbackInfo:
        "You need to give feedback to another user. If no video is shown, feel free to skip the task",
    },
    read_text: { title: "Read text" },
    watch_video: { title: "Watch video" },
    video_presentation: {
      title: "Record presentation",
      submissions: "max. submission(s)",
      reqSubmissions: "required submission(s)",
      sharedSubmissions: "max. shared submission(s)",
      reqSharedSubmissions: "required shared submission(s)",
      share: "Share submission",
      unshare: "Unshare submission",
      delete: "Delete Submission",
      delConfirm: "Are you sure you wish to delete this submission?",
      error:
        "You need {submissions} more submission(s) and {sharedSubmissions} more shared submission(s)!",
      skipped: "This is an empty (skipped) submission",
    },
    give_feedback: {
      title: "Give feedback",
      text: "Record a video as feedback to this video",
      noRating: "This video cannot be rated",
      nextTitle: "Record your feedbaack",
      yourSubmissions: "Your submission",
      noVideo: "No video found",
      noVideoDetail:
        "We could not find a video that hasn't been reviewed by another user yet.",
    },
    watch_feedback: {
      title: "Watch feedback",
      no_feedback: "No one has given feedback to your video, yet.",
      text:
        "Watch this video that another participant has given as feedback to your presentation",
    },
    gap_text: {
      title: "Fill in the blanks",
      error: "Please fill out all blanks before submitting",
    },
    rephrase: {
      title: "Rephrase",
      sentences: "Sentences",
      error: "Please fill out all fields",
    },
    categorize: {
      title: "Link Questions",
      phrases: "Phrases",
      noMore: "No more phrases",
      categories: "Categories",
      dnd: "Drag and drop here",
      error: "Please put all phrases in categories",
      correct: "Correct category",
    },
    vr_training: {
      title: "VR Training",
      requiredScore: "Required score",
      yourSubmissions: "Your submissions",
      score: "Score",
      refresh: "Refresh submissions",
    },
    goal: {
      title: "Goal",
      target: "Required number of sessions",
      progress: "Accepted sessions",
      start: "Start challenge!",
      description:
        "You have not been assigned to this goal. Click the button below, then complete the target number of sessions to progress in the seminar. Keep the conditions in mind!",
      assign: "Assign me to the goal!",
    },

    nameRequired: "Name required",
    descRequired: "Description required",

    ratings: {
      amusing: "Amusing",
      creative: "Creative",
      professional: "Professional",
    },

    videos: {
      title: "All videos",
      noSubmissions: "No submissions yet",

      sortBy: {
        title: "Sort by",
        date: "Date",
      },
      filter: {
        users: {
          title: "Users",
          detail: "Filter by user",
          placeholder: "Filter by name...",
        },
        elements: {
          title: "Elements",
          detail: "Filter by element",
        },
      },
    },
    vrScores: {
      title: "VR Scores",
      noVr: "No VR elements in this seminar",
    },
    done: "Done",
    doneText: "You are done with this seminar. Your progress has been saved",
    back: "Back to dashboard",
    finish: "Finish",
  },

  homePage: {
    welcomeMessage: "Welcome to PREZP",
    noPermissions:
      "You have no permissions. When seminar/training permissions are granted, you can reload the window and start training.",
    seminarBtnText: "PREZP Seminars",
    sessionTrainingBtnText: "PREZP Session Training",
    noSemiPermission:
      "This section is disabled because you have not licensed a PREZP Seminar. If you want to learn more about it just contact us via the chat function.",
    noSessPermission:
      "This section is disabled because you have not licensed PREZP Session Training. If you want to learn more about it just contact us via the chat function.",
  },

  invitations: {
    title: "Invitations",
    type: "Invitation type",
    all: "All",
    active: "Active",
    inactive: "Inactive",
    sent: "Sent invitations",
    revoke: "Revoke invitation",
    accept: "Accept invitation",
    decline: "Decline invitation",
    noInvitations: "You have no new invitation.",
  },

  zoomControl: "Zoom to slide",

  notifications: {
    feedback_received: "Received feedback",
    rate_video: "Upload new video for feedback",
    rating_received: "Received new feedback",
    months: "months",
    weeks: "weeks",
    days: "days",
    hours: "hours",
    minutes: "minutes",
    fewSeconds: "few seconds ago",
  },

  pfp: {
    preview: "This is a preview of your new profile picture",
    uploadNew: "Upload this image",
    chooseNew: "Choose new image",
    cancelChange: "Cancel change",
    removeImage: "Remove current image",
    recSquare: "We recommend a square image",
  },

  api_errors: {
    image_upload_failed: "Image upload failed",
    user_not_found: "User not found",
    owner_not_found: "Owner not found",
    invalid_type: "Invalid type",
    user_exists: "User already exists",
    no_user_with_this_email: "There is no user with this Email",
    password_incorrect: "Incorrect password",
    email_not_confirmed:
      "Please confirm your email by clicking on the link in your registration mail. Your account is not yet activated.",
    user_not_active: "User is not active",
    access_denied: "Access denied",
    element_not_found: "Element not found",
    block_not_found: "Block not found",
    seminar_not_found: "Seminar not found",
    user_not_authorized: "User not authorized",
    seminar_order_not_found: "Seminar order not found",
    block_data_not_found: "Block data not found",
    element_data_not_found: "Element data not found",
    invitation_not_found: "Invitation not found",
    invitation_deactivated: "Not an active invitation",
    seminar_user_not_found: "Seminar user not found",
    goal_not_found: "Goal not found",
    cannot_delete: "Cannot delete",
    device_token_failed: "An error occurred. Device could not be authorized.",
  },

  browser_warnings: {
    unsuppBrowser: "Unsupported Browser",
    unsuppBrowserTitle: "Unsupported browser detected",
    unsuppBrowserText:
      "Some features, such as the audio/video recorder might not work correctly. For the best experience, please use the Chrome browser.",
    dontShow: "Don't show me this again",
    ok: "Ok",
  },

  tour: {
    box: {
      title: "Site tour progress",
      home: "PREZP Seminars",
      dash: "PREZP Dashboard",
      goals: "Goals",
      recorder: "PREZP Recorder",
      sessions: "Sessions",
    },
    step: {
      skip: "Skip",
      exit: "Exit",
      next: "Next",
      finish: "Finish",
      close: "Close",
      of: "of",
    },
    welcome: {
      title: "Welcome to PREZP",
      body:
        "If you are new to PREZP we have a tutorial to get you started with the platform. It will show you the most important features and how to use them! Please look for the flashing blue dot in order to find the next step to your tutorial!",
      btn1: "Do not show me this again",
      btn2: "Start Tutorial",
    },
    finshed: {
      title: "Well done",
      body:
        "You completed the entire tutorial. If you would like to revisit any tutorial, a 'Start Tutorial' button is available at the top of each page.",
    },
    home: {
      0: {
        title: "Welcome to PREZP Seminars",
        body:
          "Here you will find two sections: The first is a list of seminars for each of which you are either a participant or the owner. The second is a list of your invitations to other seminars. Let us take a look at each section individually.",
      },
      1: {
        title: "List of seminars",
        body:
          "This is a list of all of your invitations to various areas of PREZP. Accept to get started.",
        // "This area shows all of your seminars. This includes both seminars that you have created and seminars in which you are participating. Click on the item to start your seminar.",
      },
      2: {
        title: "List of invitations",
        body:
          "This is a list of all of your invitations to various areas of PREZP. Currently, an invitation can relate to either a seminar or a group. Upon accepting an invitation you will be added as a participant or member of the seminar or group to which you were invited, respectively. If you choose to decline an invitation, the invitation will be deleted without notifying the owner of the seminar or the group. The owner will be able to see, however, that the invitation was removed.",
      },
      3: {
        title: "Next up",
        body:
          "This concludes our tour of PREZP Seminars. Next, let’s navigate to the dashboard by clicking on “Dashboard”",
        // "This concludes our tour of PREZP Seminars. Don’t forget, you can always come back later and repeat this part of the tour! Next, let’s navigate to the dashboard section via the left-side menu by clicking on “Dashboard”, where we will begin the next step of our tour.",
      },
    },
    dash: {
      0: {
        title: "Welcome to the PREZP Dashboard",
        body:
          "Here you will find your own personalized dashboard and useful information regarding to your training. Let us begin the tour!",
        // "Here you will find your own personalized dashboard. Throughout this portion of the tutorial you will be taken on a tour where you will be shown cards that contain a small amount of useful information pertaining to everything else available on the PREZP platform. Let us begin the tour!",
      },
      1: {
        title: "Presentations",
        body:
          "Here you can upload presentations. Improve your presentations by recording yourself with the PREZP Recorder, the PREZP Video Recorder or PREZP VR. We will visit those later in the tour. Note: Currently please use pdf-slides.",
        // "Here you can upload presentations. A presentation can be a set of PDF-slides or a PowerPoint presentation. Uploaded presentations are used as visual aids to help with recording yourself via the PREZP Recorder™ or the PREZP Video Recorder™ (We will visit those later in the tour).",
      },
      2: {
        title: "Presentation Upload",
        body: "Click this button to upload your presentation",
      },
      3: {
        title: "List of presentations",
        body:
          "Here is a list of all uploaded presentations. The microphone button leads you directly to the PREZP Recorder. The next allows you to view the presentation in full-screen. Furthermore you can download, rename or delete your presentation.",
        // "Here is a list of all of your previously uploaded presentations. The buttons next to each item allow you to view the presentation in full-screen. You can also download, rename or delete your presentation. The first button next to a presentation, the one with the microphone, when clicked will lead you directly to the PREZP Recorder™. It will automatically select that presentation, and allow you to jump directly into a recording.",
      },
      4: {
        title: "Presentation slides",
        body:
          "Slides for each presentation will appear here. Use the arrows to switch between slides.",
      },
      5: {
        title: "Goals",
        body:
          "All goals that have been created by you or were assigned to you will be listed here, with the most recently created one on top. You will learn more about goals later in the tour.",
      },
      6: {
        title: "Groups",
        body: "Groups that you are a part of will be shown here.",
      },
      7: {
        title: "Videos",
        body:
          "This card shows you all uploaded videos across all of your groups. Use the button “All Groups” to select the videos of a particular group.",
        // "This card shows you all uploaded videos across all of your groups. Use the button to show the drop-down list at the top right of the card. The list shows the videos of a particular group.",
      },
      8: {
        title: "News",
        body:
          "All platform related news and updates will be listed here in short form. Clicking the button on the top right of the card will take you to the news page, where you can also view all articles and updates.",
      },
      9: {
        title: "Well done",
        body:
          "Well done. Now you know everything there is to know about the Dashboard page. Let us move on to the PREZP Recorder. And don’t forget, if you forgot how something works or would just like to revisit any particular section, you can always start a section of the tutorial from the beginning any time.",
      },
    },
    goals: {
      0: {
        title: "PREZP Goals",
        body:
          "On this page you will find a list of all of your goals. The list contains both goals created by you and assigned to you by a group leader. Let us begin this section by setting a goal for yourself.",
      },
      1: {
        title: "Add a new goal",
        body: "Click here to set up a new goal.",
      },
      2: {
        title: "Setting up a goal",
        body:
          "Goals are important. Based on the goals you set, you can change your speaking style! Are you currently talking too fast? Then simply set your speaking rate target lower. And you will get a feeling for your speaking speed and start speaking more slowly.",
      },
      3: {
        title: "Target sessions",
        body: "Set the target number of sessions to be completed.",
      },
      4: {
        title: "Goal conditions",
        body:
          "Set the conditions needed to be completed before the goal is counted towards your completed goals.",
      },
      5: {
        title: "Users",
        body:
          "Assign users to this goal. If you are the leader of a group, then you can assign both yourself and other group members to a goal that you have created. As a leader, you are not required to assign yourself to a goal. If you are a member of a group, but not a group leader, then you can only assign yourself to a goal. (Remember, you have to assign at least one user to a goal before it can be submitted.)",
      },
      6: {
        title: "Confirm",
        body: "Click here to submit the goal.",
      },
      7: {
        title: "Done",
        body:
          "The goal you have just created will be listed in either the created goals, assigned goals or both depending on how they were assigned.",
      },
    },
    recorder: {
      0: {
        title: "PREZP Recorder",
        body:
          "In this part of the tour, you'll learn how to create both an audio recording and a video recording. Then, you'll learn how to create a session from those recordings. Remember, you just set a goal for yourself. So, keep the condition of that goal in mind when you are creating your recordings.",
      },
      1: {
        title: "List of recordings",
        body:
          "This is a list of your recordings. This is a list of your recordings. Here you can see the date and time that a recording was recorded and in which language a recording was recorded. When clicking a recording a dialog box opens that allows you to take further steps in regards to editing and converting a recording into a session etc. Also, you can click the little trash can icon to delete a particular recording.",
      },
      2: {
        title: "Recorder",
        body:
          "Here you can either record an audio track or a video. These can be later used to create sessions.",
      },
      3: {
        title: "Presentation select",
        body:
          "Select a presenation. The presentation will serve as a visual aid.",
      },
      4: {
        title: "Slides",
        body:
          "Move from one presentation slide to the next. Each time you press this button we set a timestamp. (A timestamp is a recording of the date and time that a change from one to slide to another takes place)",
      },
      5: {
        title: "Langauge select",
        body: "Select a langauge.",
      },
      6: {
        title: "Device select",
        body: "Select a recording device.",
      },
      7: {
        title: "Mute/unmute",
        body: "Here you can mute and unmute the microphone.",
      },
      8: {
        title: "Volume tester",
        body:
          "Use this to test your microphone’s volume. (Note: If the meter is not moving, check that your microphone is working and turned on)",
      },
      9: {
        title: "Record audio",
        body: "Press to start recording audio.",
      },
      10: {
        title: "Your recording",
        body: "Click the newly created recording.",
      },
      11: {
        title: "Recording details",
        body:
          "Here you choose the language of the recording (currently only English and German). You also can rename your presentation and save it.",
      },
      12: {
        title: "Switch to video",
        body: "Switch to video recording.",
      },
      13: {
        title: "Video preview",
        body: "Watch yourself in real-time while you're recording.",
      },
      14: {
        title: "Actions",
        body: "Start, pause, stop, or flip a video.",
      },
      15: {
        title: "Record video",
        body: "Record a short video of you speaking.",
      },
      16: {
        title: "Choose a recording",
        body:
          "Click on any of your recordings and let us create a session from it.",
      },
      17: {
        title: "Create session",
        body:
          "Click the 'Save recording as' button and select the 'New Session' option.",
      },
      18: {
        title: "Awesome",
        body: "Let us move on to sessions",
      },
    },
    sessions: {
      0: {
        title: "Sessions page",
        body:
          "Welcome to the Session-Analysis page. Here your presentations will be analysed.",
      },
      1: {
        title: "List of sessions",
        body: "This is a list of your saved sessions",
      },
      2: {
        title: "Filter sessions",
        body:
          "Filter your sessions easily by duration, date, name or presentation content",
      },
      3: {
        title: "Shared sessions",
        body:
          "Here you can share your sessions with other participants and get important feedback.",
      },
      4: {
        title: "Select a session",
        body: "Select a fully analyzed session to continue.",
      },
      5: {
        title: "Session details",
        body:
          "Here you will find all the details about a session you have created.",
      },
      6: {
        title: "Scores card",
        body:
          "Here we show the scores you have achieved on your sessions. This gives you an overview of your personal development.",
      },
      7: {
        title: "Slides card",
        body: "Find here the slides you used to give a presentation.",
      },
      8: {
        title: "Speech speed card",
        body: "This card shows you, on average, how fast you spoke.",
      },
      9: {
        title: "Pauses card",
        body:
          "This card shows you how many pauses you took during your speech or presentation. (Remember, this only includes pauses that are 1-second or longer.)",
      },
      10: {
        title: "Voice pitch card",
        body:
          "Get a feeling for your voice pitch during your performance. Speaking in a high-pitched voice during a presentation may cause the speaker to be perceived as nervous, unsure and untrustworthy.",
      },
      11: {
        title: "Sentiment sentences",
        body:
          "This is purely an AI analysis of whether a word, phrase or sentence has either a positive, negative or neutral sentiment.",
      },
      12: {
        title: "Lookup words",
        body:
          "Here you can see information about word choice and see if you are using too many filler words which are also known as “softeners”. Some examples of these are, “Uhm, Hmm, Uhh, you see, I mean,” etc.",
      },
      13: {
        title: "Sentiment ratio",
        body:
          'The sentiment ratio is a measurement that gauges how "positive" or "negative" of a feeling that a sentence or word conveys. When there is not a clearly defined distinction whether the word or sentence is positive or negative, it will get a sentiment rating of "neutral".',
      },
      14: {
        title: "Keywords card",
        body:
          "Here you will see which “keywords” the AI recognised in your presentation.",
      },
      15: {
        title: "Movement data",
        body:
          "In this section you will find the movement analysis of your VR training.",
      },
      16: {
        title: "Current session controls",
        body:
          "Here you can choose how many data points are shown on the graph, choose a benchmark session, or replay the chosen session.",
      },
      17: {
        title: "Zoom to slide/time",
        body:
          "With this function you can zoom in on individual slides, for example, if your presentation contains several pages and you have set timestamps for those pages.",
      },
      18: {
        title: "Select data points count",
        body:
          "With this button, the data points can be fine-tuned. The default setting is 50 data-points.",
      },
      19: {
        title: "Benchmark sessions",
        body:
          "Here you can upload a benchmark presentation. You will use this presentation as a point of reference for assessment and to serve as a guide for your training. By choosing a particular presentation style, you will possibly be able to reach your goal more efficiently.",
      },
      20: {
        title: "Sessions progress",
        body:
          "Select the 'Session Progress' menu item from the left-side menu.",
      },
      21: {
        title: "Progress page",
        body:
          "On the Progress page, all of the progress you have made in each area of focus (e.g. pitch, volume, words-per-minute etc.) from your training program can be found.",
      },
      22: {
        title: "Comparison limit",
        body: "...",
      },
      23: {
        title: "Done",
        body: "...",
      },
    },
  },
  feature: {
    locked: "This feature is locked",
    toUnlockIt: "Speak to a rep to unlock it.",
    toCloseThisWindow: "To close this dialog, click outside the card.",
  },
  maintenance: {
    label: "Maintenance",
    message: "We will be available again shortly",
  },
};

export default en;
