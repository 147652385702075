import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import store from "../store";
import {
  authGuard,
  adminGuard,
  liveTranscriptionGuard,
  analysisGuard,
  // aiFeedbackGuard,
  seminarGuard,
  challengeGuard,
  publicGroupGuard,
  // b2bGroupGuard,
} from "./utils";
import LocalStorage from "@/core/utils/LocalStorage";

const VRMode = LocalStorage.getVRMode();

const maintenanceMode = process.env.VUE_APP_MAINTENANCE_MODE_ENABLED || false;

Vue.use(VueRouter);
const routes: RouteConfig[] = [
  ...(maintenanceMode
    ? [
        {
          name: "Maintenance",
          path: "**/*",
          component: () => import("@/views/maintenance"),
        },
      ]
    : []),
  {
    name: "Home",
    path: "/",
    component: () => import("@/views/home"),
  },

  // Legal (Always Public)
  {
    name: "Imprint",
    path: "/legal/imprint",
    component: () => import("@/views/legal/Imprint.vue"),
  },
  {
    name: "Privacy Policy",
    path: "/legal/privacy",
    component: () => import("@/views/legal/PrivacyPolicy.vue"),
  },
  {
    name: "Contact",
    path: "/legal/contact",
    component: () => import("@/views/legal/Contact.vue"),
  },

  // Legal fallthrough (Redirect to imprint)
  {
    name: "",
    path: "/legal**",
    component: () => import("@/views/legal"),
  },

  // Auth
  {
    name: "",
    path: "/auth",
    component: () => import("@/views/auth"),
    children: [
      {
        name: "Login",
        path: "login",
        component: () => import("@/views/auth/pages/Login.vue"),
      },
      {
        name: "Logout",
        path: "logout",
        beforeEnter: (_, _1, next) => {
          store.dispatch("profile/logoutUser").then(() => {
            next("/auth/login");
          });
        },
      },
      {
        name: "Register",
        path: "register",
        component: () => import("@/views/auth/pages/Register.vue"),
      },
      {
        name: "Forgot Password",
        path: "forgotPassword",
        component: () => import("@/views/auth/pages/ForgotPassword.vue"),
      },
      {
        name: "Reset Password",
        path: "password/reset",
        component: () => import("@/views/auth/pages/ResetPassword.vue"),
      },
      {
        name: "Confirm Email",
        path: "email/confirm",
        component: () => import("@/views/auth/pages/ConfirmEmail.vue"),
      },
      {
        name: "Inactive Profile",
        path: "inactive",
        component: () => import("@/views/auth/pages/InactiveProfile.vue"),
      },
      {
        name: "New User",
        path: "new-user",
        component: () => import("@/views/auth/pages/NewUser.vue"),
      },
      {
        name: "Onboarding",
        path: "onboarding",
        component: () => import("@/views/auth/pages/Onboarding.vue"),
      },
      {
        name: "Register Device",
        path: "device/register",
        component: () => import("@/views/auth/pages/RegisterDevice.vue"),
      },
      {
        name: "Activate Device",
        path: "device/activate",
        component: () => import("@/views/auth/pages/ConfirmDevice.vue"),
      },
      // Legal (In/Before Auth)
      {
        name: "Login - Imprint",
        path: "imprint",
        component: () => import("@/views/legal/Imprint.vue"),
      },
      {
        name: "Login - Privacy Policy",
        path: "privacy",
        component: () => import("@/views/legal/PrivacyPolicy.vue"),
      },
      {
        name: "Login - Contact",
        path: "contact",
        component: () => import("@/views/legal/Contact.vue"),
      },
    ],
  },

  // Auth fallthrough (Redirect to login)
  {
    name: "",
    path: "/auth**",
    redirect: "/auth/login",
  },

  // Legal (In App)
  {
    name: "Dashboard - Imprint",
    path: "/imprint",
    component: () => import("@/views/legal/Imprint.vue"),
  },
  {
    name: "Dashboard - Privacy Policy",
    path: "/privacy",
    component: () => import("@/views/legal/PrivacyPolicy.vue"),
  },
  {
    name: "Dashboard - Contact",
    path: "/contact",
    component: () => import("@/views/legal/Contact.vue"),
  },

  // Dash
  {
    name: "Dashboard",
    path: "/dashboard",
    component: () => import("@/views/dashboard"),
    beforeEnter: authGuard,
  },

  // Admin
  {
    name: "Admin",
    path: "/admin",
    component: () => import("@/views/admin"),
    beforeEnter: adminGuard,
    children: [
      {
        path: "",
        redirect: "users",
      },
      {
        name: "Website Users",
        path: "users",
        beforeEnter: adminGuard,
        component: () => import("@/views/admin/pages/Users.vue"),
      },
      {
        name: "How-To Videos",
        path: "videos",
        beforeEnter: adminGuard,
        component: () => import("@/views/admin/pages/HowToVideos.vue"),
      },
      {
        name: "Slides",
        path: "slides",
        beforeEnter: adminGuard,
        component: () => import("@/views/admin/pages/Slides.vue"),
      },
      {
        name: "stripeProducts",
        path: "products",
        beforeEnter: adminGuard,
        component: () => import("@/views/admin/pages/StripeProducts.vue"),
      },
      {
        name: "stripeCoupons",
        path: "coupons",
        beforeEnter: adminGuard,
        component: () => import("@/views/admin/pages/StripeCoupons.vue"),
      },
      {
        name: "companies",
        path: "companies",
        beforeEnter: adminGuard,
        component: () => import("@/views/admin/pages/Companies.vue"),
      },
    ],
  },

  // Sessions
  {
    name: "Sessions (List)",
    path: "/sessions/list",
    component: () => import("@/views/sessions/Sessions.vue"),
    beforeEnter: analysisGuard,
  },
  {
    name: "Submitted Sessions",
    path: "/sessions/submitted",
    component: () => import("@/views/sessions/SubmittedSessions.vue"),
    beforeEnter: analysisGuard,
  },
  {
    name: "Sessions (Progress)",
    path: "/sessions/progress",
    component: () => import("@/views/sessions/Sessions.vue"),
    beforeEnter: analysisGuard,
  },
  {
    name: "Shared Sessions",
    path: "/sessions/shared/:email?",
    component: () => import("@/views/sessions/ManagedSessions.vue"),
    beforeEnter: analysisGuard,
  },

  // Groups
  {
    name: "Groups",
    path: "/groups",
    component: () => import("@/views/groups/Groups.vue"),
    beforeEnter: publicGroupGuard,
  },
  {
    name: "Group Details",
    path: "/groups/:id",
    component: () => import("@/views/groups/GroupDetails.vue"),
    beforeEnter: publicGroupGuard,
  },

  // News
  // {
  //   name: "News",
  //   path: "/news",
  //   component: () => import("@/views/news/News.vue"),
  //   beforeEnter: trainingGuard,
  // },
  // {
  //   name: "Article",
  //   path: "/news/article/:id",
  //   component: () => import("@/views/news/ArticleDetails.vue"),
  //   beforeEnter: trainingGuard,
  // },

  // Goals
  {
    name: "Goals",
    path: "/goals",
    component: () => import("@/views/goals"),
    beforeEnter: challengeGuard,
  },

  // Meetings
  // {
  //  name: "Meetings",
  //  path: "/meetings",
  //  component: () => import("@/views/meetings"),
  //  beforeEnter: authGuard,
  // },

  // Seminars
  {
    path: "/seminars",
    component: () => import("@/views/seminars"),
    children: [
      {
        path: "",
        name: "Seminars - Home",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Home.vue"),
      },
      {
        path: "dashboard/:id?",
        name: "Seminars - Dashboard",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Dashboard.vue"),
      },
      {
        path: "details/:id?",
        name: "Seminars - Details",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Details.vue"),
      },
      {
        path: "videos/:id?",
        name: "Seminars - Video",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Videos.vue"),
      },
      {
        path: "edit/:id",
        name: "Seminars - Edit",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Edit.vue"),
      },
      {
        path: "admin",
        name: "Seminars - Admin Dashboard",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Admin.vue"),
      },
      {
        path: "submission/:id",
        name: "Seminars - Submission Details",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/SubmissionDetails.vue"),
      },
    ],
  },

  // Subscription page
  {
    path: "/subscription",
    component: () => import("@/views/subscription"),
    children: [
      {
        path: "",
        redirect: "pricing",
      },
      {
        path: "pricing",
        name: "Subscription Pricing",
        component: () =>
          import("@/views/subscription/pages/SubscriptionPricing.vue"),
      },
      {
        path: "purchase",
        name: "Subscription Purchase",
        component: () =>
          import("@/views/subscription/pages/SubscriptionPurchase.vue"),
      },
      {
        path: "checkout",
        name: "Subscription Checkout",
        component: () =>
          import("@/views/subscription/pages/SubscriptionCheckout.vue"),
      },
    ],
  },

  // Company page
  {
    path: "/company",
    component: () => import("@/views/company"),
    children: [
      {
        path: "",
        name: "Company Home",
        beforeEnter: authGuard,
        component: () => import("@/views/company/pages/CompanyHome.vue"),
      },
      {
        path: "create",
        name: "Create Company",
        component: () => import("@/views/company/pages/CreateCompany.vue"),
      },
      {
        path: "dashboard",
        name: "Company Dashboard",
        component: () => import("@/views/company/pages/CompanyDashboard.vue"),
      },
      {
        path: "purchase",
        name: "Company Purchase Page",
        component: () => import("@/views/company/pages/CompanyPurchase.vue"),
      },
      {
        path: "pricing",
        name: "Company Pricing Page",
        component: () => import("@/views/company/pages/PricingPage.vue"),
      },
    ],
  },

  // Recorder
  // {
  //   name: "Recorder",
  //   path: "/recorder",
  //   component: () => import("@/views/recorder"),
  //   beforeEnter: authGuard,
  // },

  // LiveRecorder
  {
    name: "LiveRecorder",
    path: "/liverecorder",
    component: () =>
      import(`@/views/${VRMode ? "vrrecorder" : "liverecorder"}`),
    beforeEnter: liveTranscriptionGuard,
  },

  // Settings
  {
    name: "Settings",
    path: "/settings",
    component: () => import("@/views/settings"),
    beforeEnter: authGuard,
  },

  // Fallthrough
  {
    path: "**",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((_, _1, next) => {
  global.window.scrollTo(0, 0);
  // Scroll overflow divs for legal pages
  if (!VRMode)
    if (document.querySelector(".right-panel:has(> .legal-page)"))
      (document.querySelector(
        ".right-panel:has(> .legal-page)",
      ) as any).scrollTop = 0;
  next();
});

router.beforeEach((_, _1, next) => {
  store.commit("appLoading");
  next();
});

router.afterEach(() => store.commit("appLoaded"));

export default router;
