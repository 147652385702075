import GroupUser from "./GroupUser";
import GroupVideo from "./GroupVideo";
import GroupPost from "./GroupPost";

class Group {
  public ID: number;
  public Name: string;
  public CreatedAt: Date;
  public JoinedAt: Date;
  public IsOwner: boolean;
  public IsManager: boolean;
  public IsVisible: boolean;
  public IsPublic: boolean;
  public PinnedVideoID: number;
  public PinnedPostID: number;
  public Users: GroupUser[];
  public Videos: GroupVideo[];
  public Posts: GroupPost[];

  constructor(
    id: number,
    name: string,
    createdAt: Date,
    joinedAt: Date,
    isOwner: boolean,
    isManager: boolean,
    isVisible: boolean,
    isPublic: boolean,
    pinnedVideoId: number,
    pinnedPostId: number,
    users: GroupUser[],
    videos: GroupVideo[],
    posts: GroupPost[],
  ) {
    this.ID = id;
    this.Name = name;
    this.CreatedAt = createdAt;
    this.JoinedAt = joinedAt;
    this.IsOwner = isOwner;
    this.IsManager = isManager;
    this.IsVisible = isVisible;
    this.IsPublic = isPublic;
    this.PinnedVideoID = pinnedVideoId;
    this.PinnedPostID = pinnedPostId;
    this.Users = users;
    this.Videos = videos;
    this.Posts = posts;
  }

  public static parseGroup(group: any): Group {
    const created = new Date(Date.parse(group.createdAt));
    const joined = new Date(Date.parse(group.joinedAt));
    const users = (group.users as any[]).map(u => GroupUser.parseGroupUser(u));
    const videos = (group.videos as any[]).map(v =>
      GroupVideo.parseGroupVideo(v),
    );
    const posts = (group.posts as any[]).map(p => GroupPost.parseGroupPost(p));

    return new Group(
      group.id,
      group.name,
      created,
      joined,
      group.isOwner,
      group.isManager,
      group.isVisible,
      group.isPublic,
      group.pinnedVideoID,
      group.pinnedPostID,
      users,
      videos,
      posts,
    );
  }
}

export default Group;
